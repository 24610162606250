import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import { ENDPOINTS, httpService } from "../../services";
import Swal from "sweetalert2";

type Props = {
  activityId: number;
  reload: boolean;
  setReload: (reload: boolean) => void;
};

export const RescheduleActivity = ({
  activityId,
  reload,
  setReload,
}: Props) => {
  const validationSchema = Yup.object()
    .shape({
      endDate: Yup.date().required("End date is required"),
      startDate: Yup.date().required("Start date is required"),
    })
    .required();

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.RescheduleActivity).update(activityId, values);
    setReload(!reload);
    reset();
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column mt-5"
    >
      <div>
        <Controller
          control={control}
          name="startDate"
          render={({ field }) => (
            <DatePicker
              className="form-control"
              placeholderText="Select start date"
              onChange={field.onChange}
              selected={field.value}
            />
          )}
        />
      </div>
      <div className="mt-3">
        <Controller
          control={control}
          name="endDate"
          render={({ field }) => (
            <DatePicker
              className="form-control"
              placeholderText="Select end date"
              onChange={field.onChange}
              selected={field.value}
            />
          )}
        />
      </div>

      <button
        type="submit"
        className="btn btn-primary mt-3 d-flex align-items-center justify-content-center align-self-end"
      >
        {isSubmitting && (
          <span
            style={{ fontSize: "10px", textAlign: "center" }}
            className="spinner-border text-white mr-4"
          />
        )}
        <span>Save</span>
      </button>
    </form>
  );
};
