import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { ENDPOINTS, getCurrentUser, httpService } from "../../services";
import { BsPlusSquareFill, BsXSquareFill } from "react-icons/bs";

type Props = {
  reload: boolean;
  setReload: (reload: boolean) => void;
};

export const CreateDepartment = ({ reload, setReload }: Props) => {
  const user = getCurrentUser();

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("Name is required"),
      sections: Yup.array(
        Yup.object().shape({
          name: Yup.string().required("Section name is required"),
        })
      ),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sections", // unique name for the Field Array
  });

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.Departments).post({
      ...values,
      departmentId: user?.departmentId,
    });
    setReload(!reload);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="d-flex flex-column mt-4"
      >
        <div className="d-flex align-content-center">
          <div className="form-group w-100 d-flex flex-column align-items-start align-content-center">
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Department Name"
              {...register("name")}
            />
            {fields.map((item, index) => {
              return (
                <div
                  className="py-1 mt-2 d-flex align-items-center align-content-space-between"
                  key={item.id}
                >
                  <input
                    type="text"
                    className="form-control mr-2"
                    id={`sections[${index}].name`}
                    placeholder="Section name"
                    {...register(`sections[${index}].name`)}
                  />

                  <div>
                    <BsXSquareFill
                      style={{ cursor: "pointer", color: "#e7515a" }}
                      fontSize="30px"
                      onClick={() => remove(index)}
                    />
                  </div>
                </div>
              );
            })}
            <div style={{ alignSelf: "flex-start" }} className="mt-2">
              <span className="mr-2">Add Section</span>
              <BsPlusSquareFill
                style={{ cursor: "pointer", color: "#1b55e2" }}
                fontSize="30px"
                onClick={() => {
                  append({
                    name: "",
                  });
                }}
              />
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary mt-3 d-flex align-items-center justify-content-center align-self-end"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          <span>Save</span>
        </button>
      </form>
    </>
  );
};
