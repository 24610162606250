import { useEffect, useState } from "react";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { Department } from "../../models";
import { ENDPOINTS, httpService } from "../../services";

type Props = {
  onHandleChange: (value: any) => void;
};

const FilterObjectiveBySectionId = ({ onHandleChange }: Props) => {
  const [departments, setDepartments] = useState<Department[]>([]);

  useEffect(() => {
    (async () => {
      const res = await httpService(ENDPOINTS.AllDepartments).getAll();
      setDepartments(res.data ?? []);
    })();
  }, []);

  const onChange = (e: any) => {
    onHandleChange(e.target.value);
  };

  return (
    <div className="form-group">
      <div className="form-group">
        <select
          className="form-control form-control-sm nested"
          onChange={onChange}
        >
          <option value="">Select Section</option>
          {departments.map((d) => {
            return (
              <optgroup label={d.name} key={d.id}>
                {d.sections.map((s) => {
                  return (
                    <option value={s.id} key={s.id}>
                      {toUpperCaseFirstLetter(s.name)}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default FilterObjectiveBySectionId;
