import { FaGripHorizontal } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { TenantCode } from "../../models";
import {
  getCurrentTenant,
  getCurrentUser,
  logout,
  TENANT,
} from "../../services";

const Header = () => {
  const user = getCurrentUser();
  const navigate = useNavigate();
  const tenant = getCurrentTenant();

  const handleLogOut = () => {
    logout();
    navigate("/login");
  };

  return (
    <div className="header-container">
      <header className="header navbar navbar-expand-sm">
        <a
          href="/dashboard"
          className="sidebarCollapse"
          data-placement="bottom"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-menu"
          >
            <line x1={3} y1={12} x2={21} y2={12} />
            <line x1={3} y1={6} x2={21} y2={6} />
            <line x1={3} y1={18} x2={21} y2={18} />
          </svg>
        </a>
        <div className="nav-logo align-self-center">
          <Link className="navbar-brand" to="/dashboard">
            <img
              alt="logo"
              src="/assets/img/logo.svg"
              style={{ marginRight: "10px" }}
            />
            <span className="navbar-brand-name">{tenant?.name ?? ""}</span>
          </Link>
        </div>
        <ul className="navbar-item flex-row mr-auto"></ul>
        <ul className="navbar-item flex-row nav-dropdowns">
          <li className="nav-item dropdown user-profile-dropdown order-lg-0 order-1">
            <a
              href="/dashboard"
              className="nav-link dropdown-toggle user"
              id="user-profile-dropdown"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="media">
                <div className="media-body align-self-center">
                  <h6>
                    <span>Hi,</span> {user && user?.fullName}
                  </h6>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-down"
              >
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </a>
            <div
              className="dropdown-menu position-absolute animated fadeInUp"
              aria-labelledby="user-profile-dropdown"
            >
              <div>
                <Link to="/dashboard/profile" className="dropdown-item p-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                    <circle cx={12} cy={7} r={4} />
                  </svg>{" "}
                  My Profile
                </Link>
                <Link
                  to="/login"
                  className="dropdown-item p-2"
                  onClick={handleLogOut}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-log-out"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1={21} y1={12} x2={9} y2={12} />
                  </svg>{" "}
                  Sign Out
                </Link>
                <Link to="/tenants" className="dropdown-item p-2">
                  <span className="mr-1">
                    <FaGripHorizontal color="#000" />
                  </span>
                  Switch Tenant
                </Link>
              </div>
            </div>
          </li>
        </ul>
      </header>
    </div>
  );
};

export default Header;
