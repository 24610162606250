import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS, httpService, TOKEN } from "../../services";

type LoginType = {
  username: string;
  password: string;
};

export const Login = () => {
  const validationSchema = Yup.object()
    .shape({
      username: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
    })
    .required();

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    formState: { isSubmitting },
    handleSubmit,
    register,
  } = useForm<LoginType>(formOptions);
  const navigate = useNavigate();

  const onSubmit = async (values: LoginType) => {
    // Login in...
    const res = await httpService(ENDPOINTS.Auth).post(values);

    localStorage.setItem(TOKEN, res.data.token);

    navigate("/tenants");
  };

  return (
    <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
      <div className="form">
        <div className="pb-4">
          <input
            id="username"
            type="text"
            className="form-control"
            placeholder="Username"
            {...register("username")}
          />
        </div>
        <div className="pb-4">
          <input
            id="password"
            type="password"
            className="form-control"
            placeholder="Password"
            {...register("password")}
          />
        </div>
        <div className="w-100">
          <div className="field-wrapper w-100 ">
            <button
              type="submit"
              className="btn btn-success mt-3 d-flex align-items-center justify-content-center"
            >
              {isSubmitting && (
                <span
                  style={{ fontSize: "10px", textAlign: "center" }}
                  className="spinner-border text-white mr-4"
                />
              )}
              <span>Log In</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
