import { Helmet } from "react-helmet";
import { Login } from "../../components/forms";

function RenderHelmet() {
  return (
    <Helmet>
      <link
        href="/assets/css/authentication/form-2.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/css/forms/theme-checkbox-radio.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/css/forms/switches.css"
      />
      <script src="assets/js/authentication/form-2.js" />
    </Helmet>
  );
}

const Index = () => {
  return (
    <>
      <RenderHelmet />
      <div className="form-container outer">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <img
                  alt="logo"
                  src="/assets/img/logo.svg"
                  style={{ width: "120px", height: "120px" }}
                  className="mb-2"
                />
                <h1 className="mb-5">
                  Log In to <span className="text-success">M.Minutes</span>
                </h1>
                <Login />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
