export enum ROLES {
  DIRECTOR = "Director",
  SUPER_ADMIN = "Super.Admin",
  ADMIN = "Admin",
  EDITOR = "Editor",
}
export enum TenantCode {
  SHW = "SHW",
  SHA = "SHA",
  WP = "WP",
}
