import { getByTitle } from "@testing-library/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import { useRef } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { ActivitiesByDepartment, TenantCode } from "../../models";
import { getCurrentTenant } from "../../services";
import FilterByDate from "../dropdowns/filterByDate";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const tenant = getCurrentTenant();

const options = {
  indexAxis: "y" as const,
  plugins: {
    title: {
      display: true,
      text: "Activities by department",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      // stepSize: 1,
      // beginAtZero: true,
    },
  },
};

type Props = {
  meetingId: number;
  activities: ActivitiesByDepartment[];
  fromDate: Date | undefined;
  toDate: Date | undefined;
  setFromDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setToDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
};

export default function DepartmentActivityChart({
  activities,
  meetingId,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
}: Props) {
  const chartRef = useRef();
  const navigate = useNavigate();

  const onClick = (event: any) => {
    const data = getElementAtEvent(chartRef.current as any, event);

    if (data.length > 0) {
      const selectedActivity = activities.find((t, i) => i === data[0].index);
      if (selectedActivity) {
        const params = new URLSearchParams();

        if (meetingId !== 0) {
          params.append("meetingId", meetingId.toString());
        }

        if (fromDate && toDate) {
          params.append("fromDate", moment(fromDate).format("YYYY-MM-DD"));
          params.append("toDate", moment(toDate).format("YYYY-MM-DD"));
        }

        params.append("departmentId", selectedActivity.departmentId.toString());
        params.append("progress", getProgress(data[0].datasetIndex).toString());

        navigate(`/dashboard/objectives?${params}`, {
          state: {
            departmentId: selectedActivity?.departmentId,
            progress: getProgress(data[0].datasetIndex),
            meetingId: meetingId !== 0 ? meetingId : null,
            fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : null,
            toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : null,
          },
        });
      }
    }
  };

  function getProgress(progressIndex: number) {
    if (progressIndex === 0) {
      return 0;
    } else if (progressIndex === 1) {
      return 25;
    } else if (progressIndex === 2) {
      return 50;
    } else if (progressIndex === 3) {
      return 75;
    } else {
      return 100;
    }
  }

  const data = {
    labels: activities.map(
      (t) =>
        `${t.name} (${t.total} ${
          tenant && tenant.code === TenantCode.WP ? "activities" : "tasks"
        })`
    ),
    datasets: [
      {
        label: "0%",
        data: activities.map((t) => t.zero),
        backgroundColor: "#cc1d1d",
      },
      {
        label: "25%",
        data: activities.map((t) => t.twentyFive),
        backgroundColor: "#f5c905",
      },
      {
        label: "50%",
        data: activities.map((t) => t.fifty),
        backgroundColor: "#f5ed05",
      },
      {
        label: "75%",
        data: activities.map((t) => t.seventyFive),
        backgroundColor: "#95d108",
      },
      {
        label: "100%",
        data: activities.map((t) => t.hundred),
        backgroundColor: "#0ba360",
      },
    ],
  };
  return (
    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 layout-spacing">
      <div className="widget-four" style={{ cursor: "pointer" }}>
        <div className="page-header m-0 p-0">
          <div />
          <FilterByDate
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
        </div>
        <Bar
          data={data}
          options={options}
          // redraw={true}/
          onClick={onClick}
          ref={chartRef}
        />
      </div>
    </div>
  );
}
