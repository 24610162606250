import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { Department, Role, Tenant } from "../../models";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { ENDPOINTS, httpService } from "../../services";
import { useEffect, useState } from "react";

type Props = {
  reload: boolean;
  setReload: (reload: boolean) => void;
};

export const CreateUser = ({ reload, setReload }: Props) => {
  const [departments, setDepartments] = useState<Department[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [tenantIDs, setTenantIDs] = useState<number[]>([]);

  const validationSchema = Yup.object()
    .shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      userName: Yup.string().required("User Name is required"),
      password: Yup.string().required("Password is required"),
      roleId: Yup.number().required("Role id is required"),
      sectionId: Yup.number().required("Section id is required"),
    })
    .required();

  const formOptions = {
    defaultValues: {
      tenants,
    },
    resolver: yupResolver(validationSchema),
  };

  // Fetch Departments,Roles & Tenants
  useEffect(() => {
    (async function fetchDepartments() {
      const res = await httpService(ENDPOINTS.AllDepartments).getAll();
      setDepartments(res.data ?? []);
    })();

    (async function fetchRoles() {
      const res = await httpService(ENDPOINTS.Roles).getAll();
      setRoles(res.data ?? []);
    })();

    (async function fetchTenants() {
      const res = await httpService(ENDPOINTS.Tenants).getAll();
      setTenants(res.data ?? []);

      setTenantIDs(
        res.data && res.data.length > 0 && res.data.map((t: Tenant) => t.id)
      );

      reset({ tenants: res.data ?? [] });
    })();
  }, []);

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const { fields } = useFieldArray({
    control,
    name: "tenants", // unique name for the Field Array
  });

  const handleOnSubmit = async (values: any) => {
    const { tenants, ...rest } = values;
    await httpService(ENDPOINTS.Users).post({ ...rest, tenantIDs });
    setReload(!reload);
  };

  const onChangeTenantId = (id: number) => {
    if (tenantIDs.includes(id)) {
      setTenantIDs((prev) => prev.filter((t) => t !== id));
    } else {
      setTenantIDs((prev) => [...prev, id]);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="d-flex flex-column mt-4"
      >
        <div className="d-flex align-content-center">
          <div className="form-group w-100 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="First name"
              {...register("firstName", { required: true })}
            />
          </div>
          <div className="form-group ml-2 w-100 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Last name"
              {...register("lastName", { required: true })}
            />
          </div>
        </div>
        <div className="d-flex align-content-center">
          <div className="form-group w-100 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="firstName">User Name</label>
            <input
              type="text"
              className="form-control"
              id="userName"
              placeholder="User name"
              {...register("userName", { required: true })}
            />
          </div>
          <div className="form-group ml-2 w-100 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              {...register("password", { required: true })}
            />
          </div>
        </div>
        <div className="d-flex align-content-center">
          <div className="form-group w-100 mb-4 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="departmentId">Section</label>
            {/* <select
              {...register("departmentId", {
                required: "select one option",
                valueAsNumber: true,
              })}
              id="departmentId"
              className="form-control"
            >
              <option>Select department</option>
              {departments.map((d) => {
                return (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                );
              })}
            </select> */}
            <select
              className="form-control form-control-sm nested"
              id="sectionId"
              {...register("sectionId", {
                required: "select one option",
                valueAsNumber: true,
              })}
            >
              <option value="">Select Section</option>
              {departments.map((d) => {
                return (
                  <optgroup label={d.name} key={d.id}>
                    {d.sections.map((s) => {
                      return (
                        <option value={s.id} key={s.id}>
                          {toUpperCaseFirstLetter(s.name)}
                        </option>
                      );
                    })}
                  </optgroup>
                );
              })}
            </select>
          </div>
          <div className="form-group w-100 ml-2 mb-4 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="roleId">Role</label>
            <select
              {...register("roleId", {
                required: "select one option",
                valueAsNumber: true,
              })}
              id="roleId"
              className="form-control"
            >
              <option defaultValue="">Select role</option>
              {roles.map((r) => {
                return (
                  <option key={r.id} value={r.id}>
                    {toUpperCaseFirstLetter(r.name)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="form-group d-flex flex-column align-items-start">
          {fields.map((field, idx) => {
            return (
              <div
                className="n-chk my-1 d-flex flex-column"
                key={tenants[idx].id}
              >
                <label className="new-control new-checkbox checkbox-outline-default">
                  <input
                    type="checkbox"
                    value={tenants[idx].id}
                    {...register(`tenants.${idx}.id`, {
                      onChange: () => onChangeTenantId(tenants[idx].id),
                    })}
                    className="new-control-input ml-3"
                  />
                  <span className="new-control-indicator mr-2"></span>
                  {tenants[idx].name}
                </label>
              </div>
            );
          })}
        </div>

        <button
          type="submit"
          className="btn btn-primary mt-3 d-flex align-items-center justify-content-center align-self-end"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          <span>Save</span>
        </button>
      </form>
    </>
  );
};
