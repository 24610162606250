import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { yupResolver } from "@hookform/resolvers/yup";
import { ENDPOINTS, httpService } from "../../services";

export const CreateMeeting = () => {
  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("Meeting name is required"),
      venue: Yup.string().required("Meeting venue is required"),
      date: Yup.date().required("Meeting date is required"),
    })
    .required();

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.Meetings).post(values);
    reset();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="d-flex flex-column align-content-flex-start"
      >
        <div className="form-group d-flex flex-column align-items-start align-content-center mt-4">
          <label htmlFor="name">Meeting Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Meeting name"
            {...register("name")}
          />
        </div>{" "}
        <div className="form-group d-flex flex-column align-items-start align-content-center">
          <label htmlFor="venue">Meeting Venue</label>
          <input
            type="text"
            className="form-control"
            id="venue"
            placeholder="Meeting Venue"
            {...register("venue")}
          />
        </div>
        <div className="form-group d-flex flex-column align-content-start align-items-start">
          <label htmlFor="date">Meeting Date</label>
          <div className="w-100 z-9999">
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <DatePicker
                  className="form-control"
                  placeholderText="Select meeting date"
                  onChange={field.onChange}
                  selected={field.value}
                  showTimeSelect
                  timeFormat="HH:mm"
                  dateFormat="MM/dd/yyyy HH:mm"
                />
              )}
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary mt-3 d-flex align-items-center justify-content-center align-self-end"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          <span>Save</span>
        </button>
      </form>
    </>
  );
};
