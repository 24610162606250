import { useEffect, useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loader from "../../components/common/loader";
import FilterObjectivesByDepartment from "../../components/dropdowns/filterObjectivesByDepartment";
import FilterObjectivesByMeeting from "../../components/dropdowns/filterObjectivesByMeeting";
import {
  CreateMeeting,
  CreateObjective,
  CreatePlan,
  CreateWorkPlanObjective,
} from "../../components/forms";
import SendEmail from "../../components/forms/sendEmail";
import Footer from "../../components/layout/footer";
import { exportToCSV } from "../../helpers/fnHelpers";
import { Objective, TableHeader } from "../../models";
import ObjectivesReport from "../../reports/objectiveReport";
import {
  ENDPOINTS,
  getCurrentTenant,
  getCurrentUser,
  httpService,
} from "../../services";
import ObjectivesTable from "./objectivesTable";
import FilterObjectiveBySectionId from "../../components/dropdowns/filterObjectiveBySectionId";

const headers: TableHeader[] = [
  {
    id: 1,
    name: "Name",
  },
  {
    id: 2,
    name: "Section",
  },
  {
    id: 3,
    name: "Remarks",
  },
  {
    id: 4,
    name: "Start date",
  },
  {
    id: 5,
    name: "End date",
  },
  {
    id: 6,
    name: "Updated Date",
  },
  {
    id: 7,
    name: "Duration",
  },
  {
    id: 8,
    name: "Progress",
  },
  {
    id: 9,
    name: "Reschedules",
  },
  {
    id: 10,
    name: "Actions",
  },
];

type Params = {
  meetingId: number | null;
  departmentId: number;
  progress: number;
  fromDate: string;
  toDate: string;
};

const Index = () => {
  const tenant = getCurrentTenant();
  const toggle = withReactContent(Swal);
  const { state } = useLocation();
  const param = state as Params | undefined;
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [meetingId, setMeetingId] = useState<number>();
  const [departmentId, setDepartmentId] = useState<number>();
  const user = getCurrentUser();
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [loading, setLoading] = useState(false);

  const defaultQuery = () => {
    if (param) {
      const queryParam = new URLSearchParams();

      if (param.meetingId !== null) {
        queryParam.append("meetingId", param.meetingId.toString());
      }

      if (param.fromDate && param.toDate) {
        queryParam.append("fromDate", param.fromDate);
        queryParam.append("toDate", param.toDate);
      }

      queryParam.append("departmentId", param.departmentId.toString());

      queryParam.append("progress", param.progress.toString());

      return {
        endpoint: ENDPOINTS.DepartmentTasks,
        option: queryParam,
      };
    } else {
      return {
        endpoint: ENDPOINTS.MeetingObjectives,
      };
    }
  };

  const [query, setQuery] = useState(defaultQuery());

  useEffect(() => {
    setLoading(true);

    (async function fetchObjectives() {
      const res = await httpService(query.endpoint, query.option).getAll();

      setObjectives(res.data ?? []);
      setLoading(false);
    })();
  }, [query.endpoint, query.option, reload]);

  // const handleSearch = useCallback(async (term: string) => {
  //   if (term.length) {
  //     setSearching(true);
  //     const queryParam = new URLSearchParams();
  //     queryParam.append("term", term);
  //     setQuery({ endpoint: ENDPOINTS.Search, option: queryParam });
  //     setSearching(false);
  //   } else {
  //     setReload(!reload);
  //   }
  // }, []);

  const handleMeetingChange = async (value: any) => {
    const params = new URLSearchParams();

    if (value === "all") {
      setMeetingId(undefined);

      if (departmentId) {
        params.append("departmentId", departmentId.toString());
      }

      setQuery({
        endpoint: ENDPOINTS.MeetingObjectives,
        option: params,
      });
    } else {
      params.append("meetingId", value.toString());

      if (departmentId) {
        params.append("departmentId", departmentId.toString());
      }

      setMeetingId(parseInt(value));

      setQuery({
        endpoint: ENDPOINTS.MeetingObjectives,
        option: params,
      });
    }
  };

  const handleDepartmentChange = async (value: any) => {
    const params = new URLSearchParams();

    if (value === "all") {
      setDepartmentId(undefined);

      if (meetingId) {
        params.append("meetingId", meetingId.toString());
      }

      setQuery({
        endpoint: ENDPOINTS.MeetingObjectives,
        option: params,
      });
    } else {
      params.append("departmentId", value.toString());

      if (meetingId) {
        params.append("meetingId", meetingId?.toString());
      }

      setDepartmentId(parseInt(value));

      setQuery({
        endpoint: ENDPOINTS.MeetingObjectives,
        option: params,
      });
    }
  };

  const navigate = useNavigate();

  const onPrint = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "90%",
      html: <ObjectivesReport />,
    });
  };

  const onCreateObjective = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "70%",
      html: <CreateObjective reload={reload} setReload={setReload} />,
    });
  };

  const onCreateWorkPlanObjective = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "70%",
      html: <CreateWorkPlanObjective reload={reload} setReload={setReload} />,
    });
  };

  const onCreateMeeting = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <CreateMeeting />,
    });
  };

  const onCreatePlan = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <CreatePlan />,
    });
  };
  const onSendEmail = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <SendEmail />,
    });
  };

  const isDisabled = () => {
    console.log(tenant?.id, user?.roleName);
    if (
      user?.roleName === "Admin" ||
      user?.roleName === "Editor" ||
      user?.roleName === "Super.Admin" ||
      (user?.roleName === "Director" && tenant && +tenant?.id === 3)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div id="content" className="main-content">
      <div className="layout-px-spacing">
        <div className="row layout-top-spacing" id="cancel-row">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-6">
              <div className="d-flex align-items-space-between align-content-center">
                <div style={{ flex: 1 }}>
                  {param ? (
                    <div className="d-flex msg-close">
                      <svg
                        onClick={() =>
                          navigate(`/dashboard`, {
                            state: {
                              meetingId: param?.meetingId,
                              fromDate: param?.fromDate,
                              toDate: param?.toDate,
                            },
                          })
                        }
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-arrow-left close-message"
                        style={{ cursor: "pointer" }}
                      >
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline points="12 19 5 12 12 5"></polyline>
                      </svg>
                    </div>
                  ) : (
                    // <Search
                    //   title="objectives"
                    //   searching={searching}
                    //   onSubmit={handleSearch}
                    // />
                    <>
                      <button
                        className="btn btn-dark px-2 ml-2"
                        onClick={() => onCreateMeeting()}
                        disabled={
                          user &&
                          (user.roleName === "Admin" ||
                            user.roleName === "Editor" ||
                            user.roleName === "Super.Admin")
                            ? false
                            : true
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-plus"
                          style={{ color: "#fff" }}
                        >
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={5} y1={12} x2={19} y2={12} />
                        </svg>
                        New Meeting
                      </button>

                      <button
                        className="btn btn-dark px-2 ml-2"
                        onClick={() => onSendEmail()}
                        disabled={
                          user &&
                          (user.roleName === "Admin" ||
                            user.roleName === "Editor" ||
                            user.roleName === "Super.Admin")
                            ? false
                            : true
                        }
                      >
                        <FaEnvelope className="mr-2" />
                        Send Email
                      </button>
                      <button
                        className="btn btn-dark px-2 ml-2"
                        onClick={() => onCreateObjective()}
                        disabled={isDisabled()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-plus"
                          style={{ color: "#fff" }}
                        >
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={5} y1={12} x2={19} y2={12} />
                        </svg>
                        New Objective
                      </button>

                      <button
                        onClick={() => onPrint()}
                        className="btn btn-success ml-2 px-2"
                      >
                        Print Objectives
                      </button>

                      <span>
                        <button
                          onClick={() => exportToCSV()}
                          className="btn btn-success ml-2 px-2"
                        >
                          Export as CSV
                        </button>
                      </span>

                      <span>
                        <Link
                          className="btn btn-success ml-2"
                          to="/dashboard/ganttReport"
                          state={{ objectives }}
                        >
                          Gantt Report{" "}
                        </Link>
                      </span>
                    </>
                  )}
                </div>
                <FilterObjectivesByMeeting
                  onHandleChange={handleMeetingChange}
                />
                <FilterObjectiveBySectionId
                  onHandleChange={handleDepartmentChange}
                />
              </div>
              <div className="table-responsive mb-4 px-2 mt-4">
                {objectives.length > 0 ? (
                  <ObjectivesTable
                    open={open}
                    setOpen={setOpen}
                    headers={headers}
                    objectives={objectives}
                    reload={reload}
                    setReload={setReload}
                  />
                ) : loading ? (
                  <Loader />
                ) : (
                  <span>No data</span>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
