import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { ENDPOINTS, httpService } from "../../services";

type Props = {
  reload: boolean;
  setReload: (reload: boolean) => void;
};

export const CreateTenant = ({ reload, setReload }: Props) => {
  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("Name is required"),
      code: Yup.string().required("Code is required"),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.Tenants).post(values);
    setReload(!reload);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="d-flex flex-column mt-4"
      >
        <div className="d-flex align-content-center">
          <div className="form-group w-100 d-flex flex-column align-items-start align-content-center mr-2">
            <label htmlFor="name">Tenant Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Tenant Name"
              {...register("name")}
            />
          </div>
          <div className="form-group w-100 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="code">Tenant Code</label>
            <input
              type="text"
              className="form-control"
              id="code"
              placeholder="Tenant Code"
              {...register("code")}
            />
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary mt-3 d-flex align-items-center justify-content-center align-self-end"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          <span>Save</span>
        </button>
      </form>
    </>
  );
};
