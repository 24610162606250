import { useState } from "react";

type Props = {
  setProgress: React.Dispatch<React.SetStateAction<string[]>>;
  progress: string[];
};

const FilterByProgress = ({ setProgress, progress }: Props) => {
  const [selectedAll, setSelectedAll] = useState(false);

  const onChange = (e: { target: { value: string } }) => {
    if (e.target.value === "all") {
      setSelectedAll(!selectedAll);

      if (selectedAll === true) {
        setProgress([]);
      } else {
        setProgress(["0%", "25%", "50%", "75%", "100%"]);
      }
    } else {
      if (progress.includes(e.target.value)) {
        setProgress((prev) => prev.filter((p) => p !== e.target.value));
      } else {
        setProgress((prev) => [...prev, e.target.value]);
      }
    }
  };

  return (
    <div className="d-flex flex-column mx-2">
      <div className="d-flex align-items-center align-content-space-between">
        <div className="n-chk mr-2 d-flex flex-column">
          <label className="new-control new-checkbox checkbox-outline-default ">
            <input
              type="checkbox"
              value="0%"
              checked={selectedAll === true ? selectedAll : undefined}
              onChange={onChange}
              className="new-control-input"
            />
            <span className="new-control-indicator mr-2"></span>
            0%
          </label>
          <label className="new-control new-checkbox checkbox-outline-default">
            <input
              type="checkbox"
              value="25%"
              checked={selectedAll === true ? selectedAll : undefined}
              onChange={onChange}
              className="new-control-input"
            />
            <span className="new-control-indicator mr-2"></span>
            25%
          </label>
        </div>
        <div className="n-chk mr-2 d-flex flex-column">
          <label className="new-control new-checkbox checkbox-outline-default">
            <input
              type="checkbox"
              value="50%"
              onChange={onChange}
              checked={selectedAll === true ? selectedAll : undefined}
              className="new-control-input"
            />
            <span className="new-control-indicator mr-2"></span>
            50%
          </label>
          <label className="new-control new-checkbox checkbox-outline-default">
            <input
              type="checkbox"
              value="75%"
              checked={selectedAll === true ? selectedAll : undefined}
              onChange={onChange}
              className="new-control-input"
            />
            <span className="new-control-indicator mr-2"></span>
            75%
          </label>
        </div>
        <div className="n-chk mr-2  d-flex flex-column">
          <label className="new-control new-checkbox checkbox-outline-default">
            <input
              type="checkbox"
              value="100%"
              checked={selectedAll === true ? selectedAll : undefined}
              onChange={onChange}
              className="new-control-input"
            />
            <span className="new-control-indicator mr-2"></span>
            100%
          </label>
          <label className="new-control new-checkbox checkbox-outline-default">
            <input
              type="checkbox"
              value="all"
              checked={selectedAll}
              onChange={onChange}
              className="new-control-input"
            />
            <span className="new-control-indicator mr-2"></span>
            All
          </label>
        </div>
      </div>
    </div>
  );
};

export default FilterByProgress;
