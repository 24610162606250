import React from "react";
import { Department, Section, SectionUnit, TableHeader } from "../../models";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { FaEllipsisH, FaEllipsisV } from "react-icons/fa";
import moment from "moment";
import { ENDPOINTS, httpService } from "../../services";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SureToDelete from "../../components/common/SureToDelete";
import { CreateDepartmentSection, UpdateSection } from "../../components/forms";

type Props = {
  headers: TableHeader[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  departments: Department[];
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const DepartmentsTable = ({
  departments,
  open,
  reload,
  headers,
  setOpen,
  setReload,
}: Props) => {
  const toggle = withReactContent(Swal);

  const handleDelete = async (id: number, ENDPOINT: ENDPOINTS | undefined) => {
    if (ENDPOINT) {
      await httpService(ENDPOINT).delete(id);
      setReload(!reload);
    }
  };

  const onDelete = (id: number, ENDPOINT: ENDPOINTS | undefined) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: (
        <SureToDelete id={id} onDelete={handleDelete} ENDPOINT={ENDPOINT} />
      ),
    });
  };

  const createSections = (department: Department) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: (
        <CreateDepartmentSection
          department={department}
          reload={reload}
          setReload={setReload}
        />
      ),
    });
  };

  const onUpdateSection = (section: Section) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: (
        <UpdateSection
          section={section}
          reload={reload}
          setReload={setReload}
        />
      ),
    });
  };

  return (
    <div>
      {departments.map((d: Department) => {
        return (
          <div id="toggleAccordion" key={d.id}>
            <div className="card mt-2">
              <div className="px-2 py-3">
                <div
                  className="collapsed d-flex justify-content-between d-flex"
                  data-toggle="collapse"
                  data-target={`#accordion${d.id}`}
                  aria-controls={`accordion${d.id}`}
                >
                  <span className="objective_text">
                    {toUpperCaseFirstLetter(d.name)}
                  </span>

                  <div className="dropdown custom-dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <FaEllipsisV size={"20px"} color="#555" />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink1"
                    >
                      <button
                        className="dropdown-item"
                        onClick={() => createSections(d)}
                      >
                        Add Sections
                      </button>
                      <button
                        className="dropdown-item"
                        onClick={() => onDelete(d.id, ENDPOINTS.Departments)}
                      >
                        Delete Department
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id={`accordion${d.id}`}
                className="collapse bg-gray"
                data-parent="#toggleAccordion"
              >
                <div className="card-body p-0 m-10 bg-gray">
                  <table className="table">
                    <thead>
                      <tr>
                        {headers.map((h) => (
                          <th style={{ fontSize: "10px" }} key={h.id}>
                            {h.name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {d.sections?.map((s) => {
                        return (
                          <tr key={s.id} className="">
                            <td className="">
                              {toUpperCaseFirstLetter(s.name)}
                            </td>
                            <td className="">
                              {moment(s.createdAt).format("L")}
                            </td>
                            <td>
                              <div className="dropdown custom-dropdown">
                                <a
                                  className="dropdown-toggle"
                                  href="#"
                                  role="button"
                                  id="dropdownMenuLink1"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <FaEllipsisH size={"20px"} color="#555" />
                                </a>

                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuLink1"
                                >
                                  <button
                                    className="dropdown-item"
                                    onClick={() => onUpdateSection(s)}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="dropdown-item"
                                    onClick={() =>
                                      onDelete(s.id, ENDPOINTS.DeleteSection)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DepartmentsTable;
