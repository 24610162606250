import { Navigate } from "react-router";
import { getCurrentTenant, getCurrentUser } from "../services";

type Props = {
  children: any;
};

const RequiredAuth = ({ children }: Props) => {
  const user = getCurrentUser();
  const tenant = getCurrentTenant();

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!tenant) {
    return <Navigate to="/tenants" replace />;
  }

  return children;
};

export default RequiredAuth;
