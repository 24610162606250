import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Activity, SubmitActivity } from "../../models";
import { ENDPOINTS, httpService } from "../../services";

type Props = {
  activity: Activity;
  reload: boolean;
  setReload: (reload: boolean) => void;
};

export const UpdateActivityStatus = ({
  activity,
  reload,
  setReload,
}: Props) => {
  const validationSchema = Yup.object()
    .shape({
      progress: Yup.string().required("Progress is required"),
      remark: Yup.string().required("Topic is required"),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: { progress: activity.progress, remark: "" },
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm<SubmitActivity>(formOptions);

  const handleOnSubmit = async (values: SubmitActivity) => {
    await httpService(ENDPOINTS.SubmitActivity).update(activity.id, values);
    setReload(!reload);
    reset();
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column mt-4"
    >
      <div className="n-chk mb-2 d-flex flex-column align-items-start align-content-center">
        <div>
          <label htmlFor="inputRemark">Progress</label>
        </div>
        <div className="d-flex flex-column align-items-start align-content-center">
          <div>
            <label className="new-control new-radio radio-primary pr-3">
              <input
                type="radio"
                className="new-control-input my-1 mr-2"
                value="0%"
                {...register("progress")}
              />
              <span className="new-control-indicator"></span>0%
            </label>
            <label className="new-control new-radio radio-primary pr-2">
              <input
                type="radio"
                className="new-control-input my-1 mr-2"
                value="25%"
                {...register("progress")}
              />
              <span className="new-control-indicator"></span>25%
            </label>
            <label className="new-control new-radio radio-primary">
              <input
                type="radio"
                className="new-control-input my-1 mr-2"
                value="50%"
                {...register("progress")}
              />
              <span className="new-control-indicator"></span>50%
            </label>
          </div>
          <div>
            <label className="new-control new-radio radio-primary pr-2">
              <input
                type="radio"
                className="new-control-input my-1 mr-2"
                value="75%"
                {...register("progress")}
              />
              <span className="new-control-indicator"></span>75%
            </label>
            <label className="new-control new-radio radio-primary">
              <input
                type="radio"
                className="new-control-input my-1 mr-2"
                value="100%"
                {...register("progress")}
              />
              <span className="new-control-indicator"></span>100%
            </label>
          </div>
        </div>
      </div>
      <div className="form-group mb-4 d-flex flex-column align-items-start align-content-center">
        <label htmlFor="inputRemark">Remark</label>
        <textarea
          className="form-control"
          id="inputRemark"
          placeholder="Add Remark"
          {...register("remark")}
        />
      </div>
      <button
        type="submit"
        className="btn btn-primary mt-3 d-flex align-items-center justify-content-center align-self-end"
      >
        {isSubmitting && (
          <span
            style={{ fontSize: "10px", textAlign: "center" }}
            className="spinner-border text-white mr-4"
          />
        )}
        <span>Update</span>
      </button>
    </form>
  );
};
