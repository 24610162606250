import { ViewMode, Gantt, Task, DisplayOption } from "gantt-task-react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ViewSwitcher } from "../components/common/viewSwitcher";
import { formatObjectives } from "../helpers/fnHelpers";
import { Objective } from "../models";

type Params = {
  objectives: Objective[];
};

interface DateFormat extends DisplayOption {
  locale: string;
}

const NewGanttReport = () => {
  const { state } = useLocation();
  const params = state as Params;
  const [view, setView] = useState(ViewMode.Month);
  const [formattedDate, setFormattedDate] = useState<DisplayOption>();
  const [tasks, setTasks] = useState<Task[]>(
    formatObjectives(params.objectives)
  );
  const [isChecked, setIsChecked] = useState(true);

  let columnWidth = 50;
  if (view === ViewMode.Month) {
    columnWidth = 100;
  } else if (view === ViewMode.Week) {
    columnWidth = 100;
  } else if (view === ViewMode.Year) {
    columnWidth = 500;
  }

  const handleExpanderClick = (task: any) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  return (
    <div className="mx-4 my-4">
      <ViewSwitcher
        onViewModeChange={(viewMode: any) => setView(viewMode)}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
      />
      {/* <h3>Gantt Report</h3> */}
      {tasks.length > 0 && (
        <Gantt
          tasks={tasks}
          viewMode={view}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? "155px" : ""}
          columnWidth={columnWidth}
          rowHeight={50}
          fontSize="12px"
        />
      )}
    </div>
  );
};

export default NewGanttReport;
