import { Meeting, Tenant } from "../../models";
import moment from "moment";
import { useEffect, useState } from "react";
import { ENDPOINTS, httpService, TENANT } from "../../services";

type Props = {
  onHandleChange: (value: any) => void;
};

const FilterReportByMeeting = ({ onHandleChange }: Props) => {
  const tenant = JSON.parse(localStorage.getItem(TENANT) ?? "{}") as Tenant;
  const [meetings, setMeetings] = useState<Meeting[]>([]);

  const onChange = (e: any) => {
    onHandleChange(e.target.value);
  };

  useEffect(() => {
    (async function fetchMeetings() {
      const res = await httpService(ENDPOINTS.Meetings).getAll();

      setMeetings(res.data ?? []);
    })();
  }, []);

  return (
    <div className="form-group mr-2">
      <div className="form-group">
        <select
          id="meetingId"
          className="form-control form-control-sm"
          onChange={onChange}
        >
          <option value="all">All Meeting</option>
          {meetings.map((m) => {
            return (
              <option key={m.id} value={m.id}>
                {m.name} ({moment(m.date).format("ll")})
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default FilterReportByMeeting;
