import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ActivityChart from "../../components/charts/activityChart";
import DepartmentActivityChart from "../../components/charts/departmentActivityChart";
import FilterActivityByMeetingId from "../../components/dropdowns/filterActivityByMeetingId";
import FilterObjectivesByDepartment from "../../components/dropdowns/filterObjectivesByDepartment";
import Footer from "../../components/layout/footer";
import { ActivitiesByDepartment, ActivityStats } from "../../models";
import { ENDPOINTS, httpService } from "../../services";
import FilterObjectiveBySectionId from "../../components/dropdowns/filterObjectiveBySectionId";

type Params = {
  meetingId: number | null;
  fromDate: string;
  toDate: string;
};

const Index = () => {
  const { state } = useLocation();

  const param = state as Params | undefined;

  const [activities, setActivities] = useState<ActivitiesByDepartment[]>([]);

  const [stats, setStats] = useState<ActivityStats>(new ActivityStats());

  const [fromDate, setFromDate] = useState<Date | undefined>(
    param && param.fromDate ? moment(param.fromDate).toDate() : undefined
  );
  const [toDate, setToDate] = useState<Date | undefined>(
    param && param.toDate ? moment(param.toDate).toDate() : undefined
  );

  const [meetingId, setMeetingId] = useState(
    param && param.meetingId ? param.meetingId : 0
  );

  const [departmentId, setDepartmentId] = useState(0);

  const handleMeetingChange = async (value: any) => {
    if (value === "all") {
      setMeetingId(0);
    } else {
      setMeetingId(parseInt(value));
    }
  };

  const handleDepartmentChange = async (value: any) => {
    if (value === "all") {
      setDepartmentId(0);
    } else {
      setDepartmentId(parseInt(value));
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchStats = async () => {
      const params = new URLSearchParams();
      params.append("meetingId", meetingId.toString());
      params.append("departmentId", departmentId.toString());

      const res = await httpService(ENDPOINTS.ActivitiesStats, params).getAll();

      setStats(res.data ?? ({} as ActivityStats));
    };

    fetchStats();

    return () => {
      isMounted = false;
    };
  }, [meetingId, departmentId]);

  useEffect(() => {
    let isMounted = true;

    const fetchActivities = async () => {
      const params = new URLSearchParams();

      if (meetingId !== 0) {
        params.append("meetingId", meetingId.toString());
      }

      if ((fromDate && toDate) || (param?.fromDate && param?.toDate)) {
        params.append("fromDate", moment(fromDate).format("YYYY-MM-DD"));
        params.append("toDate", moment(toDate).format("YYYY-MM-DD"));
      }

      const res = await httpService(
        ENDPOINTS.ActivitiesByDepartment,
        params
      ).getAll();

      setActivities(res.data ?? []);
    };

    fetchActivities();

    return () => {
      isMounted = false;
    };
  }, [meetingId, fromDate, toDate]);

  return (
    <>
      <Helmet>
        <link
          href="/plugins/apex/apexcharts.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="/assets/css/dashboard/dash_2.css"
          rel="stylesheet"
          type="text/css"
        />
        <script src="/plugins/apex/apexcharts.min.js" />
        <script src="/assets/js/dashboard/dash_2.js" />
      </Helmet>

      <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header my-3">
            <div className="page-title">
              <h3>Analytics Dashboard</h3>
            </div>
            <div className="dropdown filter custom-dropdown-icon d-flex align-items-center">
              <FilterActivityByMeetingId
                onHandleChange={handleMeetingChange}
                currentMeetingId={meetingId}
              />
              <FilterObjectiveBySectionId
                onHandleChange={handleDepartmentChange}
              />
            </div>
          </div>
          <div className="row layout-top-spacing">
            <DepartmentActivityChart
              activities={activities}
              meetingId={meetingId}
              setFromDate={setFromDate}
              fromDate={fromDate}
              setToDate={setToDate}
              toDate={toDate}
            />
            <ActivityChart stats={stats} />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Index;
