import moment from "moment";
import { forwardRef, useRef, useState } from "react";
import { FaPrint } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import FilterByProgress from "../components/dropdowns/filterByProgress";
import FilterObjectivesByDepartment from "../components/dropdowns/filterObjectivesByDepartment";
import FilterReportByMeeting from "../components/dropdowns/filterReportByMeeting";
import { toUpperCaseFirstLetter } from "../helpers/fnHelpers";
import { Objective } from "../models";
import { ENDPOINTS, httpService } from "../services";
import FilterObjectiveBySectionId from "../components/dropdowns/filterObjectiveBySectionId";

const ObjectivesReport = () => {
  const componentRef = useRef(null);
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [meetingId, setMeetingId] = useState<number>();
  const [departmentId, setDepartmentId] = useState<number>();
  const [progress, setProgress] = useState<string[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);
  // const { isLoading, data: agenda } = useFetch<Agenda>(query);

  const onMeetingIdChange = (value: any) => {
    if (value === "all") {
      setMeetingId(undefined);
    } else {
      setMeetingId(parseInt(value));
    }
  };

  const onDepartmentIdChange = (value: any) => {
    if (value === "all") {
      setDepartmentId(undefined);
    } else {
      setDepartmentId(parseInt(value));
    }
  };

  const onGenerate = async () => {
    setIsGenerating(true);

    let bodyParams = { meetingId, departmentId, progress };

    const res = await httpService(ENDPOINTS.Report).post(bodyParams);

    setObjectives(res.data ?? []);

    setIsGenerating(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex align-items-start">
        <span className="pt-1">
          <FilterReportByMeeting onHandleChange={onMeetingIdChange} />
        </span>
        <span className="pt-1">
          <FilterObjectiveBySectionId onHandleChange={onDepartmentIdChange} />
        </span>
        <FilterByProgress setProgress={setProgress} progress={progress} />
        <span className="mx-2 mt-1">
          <button className="btn btn-success" onClick={onGenerate}>
            {isGenerating && (
              <span className="spinner-border spinner-grow-sm text-white mr-2" />
            )}
            Generate
          </button>
        </span>
        <span className="ml-2 mt-1">
          <button
            className="btn btn-primary"
            onClick={handlePrint}
            disabled={objectives.length ? false : true}
          >
            <FaPrint /> Print
          </button>
        </span>
      </div>
      <ObjectivesTable objectives={objectives} ref={componentRef} />
    </div>
  );
};

export default ObjectivesReport;

const ObjectivesTable = forwardRef((props: any, ref: any) => {
  const objectives =
    props.objectives &&
    props.objectives.filter((a: Objective) => a.activities.length);

  return (
    <div ref={ref} className="d-flex flex-column mt-4 mx-4">
      <div className="d-flex mt-4 border justify-content-between">
        <div className="border-right p-1">
          <img src="/assets/img/logo.svg" width={100} height={100} alt="logo" />
        </div>
        <div className="mr-4">
          <h4 className="font-weight-bold text-align-center mt-3">
            Jamhuuriyadda Somaliland <br />
            <p className="pt-2">Dawlada Hoose ee Degmada Berbera </p>
          </h4>
        </div>
        <div className="border-left p-1">
          <img src="/assets/img/logo.png" width={100} height={100} alt="logo" />
        </div>
      </div>
      <table className="table">
        <thead className="bg-white ">
          <tr>
            <th className="text-left " scope="col">
              Mawduuca
            </th>
            <th className="text-left" scope="col">
              Qodobadda
            </th>
            <th className="text-left" scope="col">
              Xiliga fulinta
            </th>
            <th className="text-left" scope="col">
              Masuulka
            </th>
            <th className="text-left" scope="col">
              Progress
            </th>
            {/* <th className="text-left" scope="col">
              Updated Date
            </th> */}
            <th className="text-left" scope="col">
              Duration
            </th>
          </tr>
        </thead>
        <tbody>
          {objectives.map((a: Objective) => {
            return (
              <tr key={a.id}>
                <td className="text-left font-weight-bold">
                  {toUpperCaseFirstLetter(a.topic)}
                </td>
                <td className="text-left p-2">
                  {a.activities.map((activity) => {
                    return (
                      <ul
                        className="text-left font-weight-normal"
                        key={activity.id}
                      >
                        <li>{toUpperCaseFirstLetter(activity.name)}</li>
                      </ul>
                    );
                  })}
                </td>
                <td className="text-left font-weight-normal">
                  {a.activities.map((activity) => {
                    return (
                      <div className="text-left my-2" key={activity.id}>
                        {activity.endDate &&
                          moment(activity.endDate).format("l")}
                      </div>
                    );
                  })}
                </td>
                <td className="text-left font-weight-normal">
                  {a.activities.map((activity) => {
                    return (
                      <div className="text-left" key={activity.id}>
                        {activity.section?.name}
                      </div>
                    );
                  })}
                </td>
                <td className="text-left font-weight-normal">
                  {a.activities.map((activity) => {
                    return (
                      <div className="text-left" key={activity.id}>
                        {activity.progress}
                      </div>
                    );
                  })}
                </td>
                {/* <td className="text-left font-weight-normal">
                  {a.activities.map((activity) => {
                    return (
                      <div className="text-left" key={activity.id}>
                        {activity.updatedAt !== null
                          ? moment(activity.updatedAt).format("L")
                          : ""}
                      </div>
                    );
                  })}
                </td> */}
                <td className="text-left font-weight-normal">
                  {a.activities.map((activity) => {
                    return (
                      <div className="text-left" key={activity.id}>
                        {activity.duration > 0 &&
                        activity.updatedAt !== null ? (
                          <span className="text-success">
                            {activity.duration > 1
                              ? `${activity.duration} Days`
                              : `${activity.duration} Day`}
                          </span>
                        ) : (
                          <span className="text-danger">
                            {activity.duration > 1
                              ? `${activity.duration} Days`
                              : `${activity.duration} Day`}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});
