import moment from "moment";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ENDPOINTS, getCurrentUser, httpService } from "../services";
import { Task } from "gantt-task-react";
import { Objective, RoleType } from "../models";

const FILETYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const FILE_EXTENSION = ".xlsx";

export function ConstructSearchParams(
  page: number,
  perPage: number
): URLSearchParams {
  const params = new URLSearchParams();
  params.append("page", page.toString());
  params.append("size", perPage.toString());
  return params;
}

export function toUpperCaseFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
export function displayString(text: string, count: number) {
  return text.slice(0, count) + (text.length > count ? "..." : "");
}

export async function exportToCSV() {
  const { data: activities } = await httpService(ENDPOINTS.Activities).getAll();

  const filteredActions = activities.map((a: any) => {
    return Object.assign(
      {},
      {
        Id: a.id,
        Name: a.name,
        Section: a.section?.name,
        Objective: a.objective?.topic,
        Progress: a.progress,
      }
    );
  });

  const ws = XLSX.utils.json_to_sheet(filteredActions);

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const data = new Blob([excelBuffer], { type: FILETYPE });

  FileSaver.saveAs(data, "objectives" + FILE_EXTENSION);
}

export function initTasks() {
  const currentDate = new Date();
  const tasks: Task[] = [
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      name: "Project",
      id: "ProjectSample",
      // progress: 25,
      type: "project",
      hideChildren: true,
      progress: 100,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        2,
        12,
        28
      ),
      name: "Idea",
      id: "Task 0",
      // progress: 45,
      type: "task",
      project: "ProjectSample",
      progress: 50,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
      name: "Research",
      id: "Task 1",
      // progress: 25,
      // dependencies: ["Task 0"],
      type: "task",
      project: "ProjectSample",
      progress: 80,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
      name: "Discussion with team",
      id: "Task 2",
      // progress: 10,
      // dependencies: ["Task 1"],
      type: "task",
      project: "ProjectSample",
      progress: 0,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
      name: "Developing",
      id: "Task 3",
      // progress: 2,
      // dependencies: ["Task 2"],
      type: "task",
      project: "ProjectSample",
      progress: 100,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      name: "Test project",
      id: "TestSample",
      // progress: 25,
      type: "project",
      hideChildren: true,
      progress: 100,
    },

    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
      name: "Review",
      id: "Task 4",
      type: "task",
      // progress: 70,
      // dependencies: ["Task 2"],
      project: "TestSample",
      progress: 10,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      name: "Release",
      id: "Task 6",
      // progress: currentDate.getMonth(),
      type: "task",
      // dependencies: ["Task 4"],
      project: "TestSample",
      progress: 30,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
      name: "Party Time",
      id: "Task 9",
      // progress: 0,
      // isDisabled: true,
      type: "task",
      project: "TestSample",
      progress: 100,
    },
  ];
  return tasks;
}

export function getStartEndDateForProject(tasks: any, projectId: any) {
  const projectTasks = tasks.filter((t: any) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;
  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}

export function formatObjectives(objectives: Objective[]) {
  // find Activities
  const activities = objectives
    .map((o) => o.activities)
    .flat(1)
    .map((ac, idx: number) => {
      return {
        start: new Date(ac.startDate ?? ac.createdAt),
        end: new Date(ac.endDate ?? ac.createdAt),
        name: ac.name,
        id: ac.id,
        // progress: 0,
        // isDisabled: true,
        type: "task",
        project: ac.objectiveId,
        progress: +ac.progress.slice(0, -1),
        styles: {
          backgroundColor: "#dc3545",
          progressColor: "#8dbf42",
          progressSelectedColor: "#8dbf42",
        },
      };
    });

  // Find objectives
  const obj = objectives
    .filter((obj: any) => obj.activities.length !== 0)
    .map((obj: any, idx: number) => {
      let start = new Date(
        Math.min(
          ...obj.activities.map((a: any) => {
            return new Date(a.startDate ?? a.createdAt);
          })
        )
      );

      let end = new Date(
        Math.max(
          ...obj.activities.map((a: any) => {
            return new Date(a.endDate ?? a.createdAt);
          })
        )
      );

      return {
        start,
        end,
        name: obj.topic,
        id: obj.id,
        // progress: 0,
        // isDisabled: true,
        type: "project",
        hideChildren: true,
        styles: {
          backgroundColor: "#000",
        },
      };
    });

  // Sort objective with their activities
  const result = obj
    .map((obj) => {
      const a = activities.filter((ac) => ac.project === obj.id);
      const b = a.map((ac, indx) => {
        ac.name = `${indx + 1}. ${ac.name}`;

        return ac;
      });
      return [obj, ...b];
    })
    .flat(1);

  return result as Task[];
}

export function GetUserWithEndPoints() {
  const user = getCurrentUser();
  const allEndPoints = [
    "dashboard",
    "objectives",
    "departments",
    "users",
    "tenants",
    "profile",
  ];
  const notRestrictedEndpoints = ["dashboard", "objectives"];

  return {
    user: user,
    endPoints:
      user?.roleName === RoleType.Admin ||
      user?.roleName === RoleType.SuperAdmin
        ? allEndPoints
        : user?.roleName === RoleType.Editor
        ? [...notRestrictedEndpoints, "departments"]
        : notRestrictedEndpoints,
  };
}
