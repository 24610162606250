import Header from "./header";
import NavBar from "./navbar";

type Props = {
  children: JSX.Element;
};

const Index = ({ children }: Props) => {
  return (
    <>
      <Header />
      <NavBar />
      {children}
    </>
  );
};

export default Index;
