import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CreateTenant } from "../../components/forms";
import Footer from "../../components/layout/footer";
import { TableHeader, Tenant } from "../../models";
import { ENDPOINTS, getCurrentUser, httpService } from "../../services";
import TenantsTable from "./tenantsTable";
import Loader from "../../components/common/loader";

const headers: TableHeader[] = [
  {
    id: 1,
    name: "Tenant Name",
  },
  {
    id: 2,
    name: "Created At",
  },
  {
    id: 5,
    name: "Actions",
  },
];
const Index = () => {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const user = getCurrentUser();
  const toggle = withReactContent(Swal);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  // Fetch Tenants
  useEffect(() => {
    setLoading(true);
    (async function fetchTenants() {
      const res = await httpService(ENDPOINTS.Tenants).getAll();

      setTenants(res.data ?? []);
      setLoading(false);
    })();
  }, [reload]);

  const onCreateTenant = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "50%",
      html: <CreateTenant reload={reload} setReload={setReload} />,
    });
  };

  return (
    <div id="content" className="main-content">
      <div className="layout-px-spacing">
        <div className="row layout-top-spacing" id="cancel-row">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-6">
              <div className="d-flex align-items-space-between align-centent-center">
                <div style={{ flex: 1 }}>{/* left side */}</div>
                <div>
                  <button
                    className="btn btn-dark mr-2"
                    onClick={() => onCreateTenant()}
                  >
                    New Tenant
                  </button>
                </div>
              </div>
              <div className="table-responsive mb-4 px-2 mt-4">
                {tenants.length > 0 ? (
                  <TenantsTable
                    headers={headers}
                    tenants={tenants}
                    reload={reload}
                    setReload={setReload}
                  />
                ) : loading ? (
                  <Loader />
                ) : (
                  <span>No data</span>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
