import { Navigate } from "react-router";
import { getCurrentUser } from "../services";
import { RoleType } from "../models";
import { GetUserWithEndPoints } from "../helpers/fnHelpers";

type Props = {
  path: string;
  children: any;
};

const RequiredPermission = ({ path, children }: Props) => {
  const user = GetUserWithEndPoints();

  if (!user.endPoints.includes(path))
    return <Navigate to="/dashboard" replace />;

  // if (
  //   user?.roleName !== "Admin" &&
  //   restrictedEndpoints.includes(path) &&
  //   user?.roleName !== "Super.Admin"
  // ) {
  //   if (user?.roleName === "Editor" && path === "departments") return children;

  //   return <Navigate to="/dashboard" replace />;
  // }

  // if (user?.roleName === "Editor" && path === "sections") {
  //   return <Navigate to="/dashboard" replace />;
  // }

  // if (user?.roleName === "Admin" && path === "tenants")
  //   return <Navigate to="/dashboard" replace />;

  return children;
};

export default RequiredPermission;
