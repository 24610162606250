import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { ENDPOINTS, getCurrentUser, httpService } from "../../services";
import { Department, Section } from "../../models";
import { useEffect, useState } from "react";

type Props = {
  section: Section;
  reload: boolean;
  setReload: (reload: boolean) => void;
};

export const UpdateSection = ({ section, reload, setReload }: Props) => {
  const [departments, setDepartments] = useState<Department[]>([]);
  const user = getCurrentUser();

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("Name is required"),
      departmentId: Yup.number().required("Department id is required"),
    })
    .required();

  const formOptions = {
    defaultValues: { name: section.name, departmentId: section.departmentId },
    resolver: yupResolver(validationSchema),
  };

  useEffect(() => {
    (async function fetchDepartments() {
      const res = await httpService(ENDPOINTS.AllDepartments).getAll();
      setDepartments(res.data ?? []);
    })();
  }, []);

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.UpdateSection).update(section.id, values);
    setReload(!reload);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="d-flex flex-column mt-4"
      >
        <div className="d-flex align-content-center mt-4">
          <div className="form-group w-100 d-flex flex-column align-items-start align-content-center">
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Tenant Name"
              {...register("name")}
            />
            <select
              {...register("departmentId")}
              id="departmentId"
              className="form-control mt-3"
            >
              <option>Select department</option>
              {departments.map((d) => {
                return (
                  <option
                    key={d.id}
                    value={d.id}
                    selected={d.id === section.departmentId}
                  >
                    {d.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary mt-3 d-flex align-items-center justify-content-center align-self-end"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          <span>Update</span>
        </button>
      </form>
    </>
  );
};
