import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { PermissionList } from "../../models";
import { BASE_URL, ENDPOINTS, httpService } from "../../services";

export const CreateRole = () => {
  const [name, setName] = useState("");
  const [permissions, setpermissions] = useState<PermissionList[]>([]);
  const [errors, setErrors] = useState(false);
  const addedpermissions: string[] = [];

  useEffect(() => {
    async function fetchpermissions() {
      const res = await fetch("https://minutes.slmof.org/api/permissions");

      const permissions = await res.json();

      setpermissions(permissions);
    }

    fetchpermissions();
  }, []);

  const handleSubmit = async () => {
    if (name.length === 0) {
      setErrors(true);
    } else {
      await httpService(ENDPOINTS.Roles).post({
        name,
        permissions: addedpermissions,
      });
    }
  };

  const onChange = (e: any) => {
    if (addedpermissions.includes(e.target.value)) {
      const index = addedpermissions.indexOf(e.target.value);
      addedpermissions.splice(index, 1);
    } else {
      addedpermissions.push(e.target.value);
    }
  };
  return (
    <div className="d-flex flex-column mt-4">
      <div className="form-group d-flex flex-column align-items-start align-content-center">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          id="name"
          value={name}
          placeholder="Role name"
          required={true}
          onChange={(e) => setName(e.target.value)}
        />
        {errors && <p style={{ color: "red" }}>Name is required</p>}
      </div>
      <div className="d-flex flex-column align-items-start">
        <label htmlFor="name">permissions</label>
        {permissions.map((l) => {
          return (
            <div className="d-flex flex-column align-items-start">
              <label className="mb-2 bg-info rounded-sm p-1">{l.title}</label>
              <div className="d-flex align-items-center align-content-space-between">
                {l.permissions.map((p) => {
                  return (
                    <div className="n-chk mr-2">
                      <label className="new-control new-checkbox checkbox-outline-default">
                        <input
                          type="checkbox"
                          value={p.value}
                          onChange={onChange}
                          className="new-control-input"
                        />
                        <span className="new-control-indicator mr-2"></span>
                        {p.title}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <button
        onClick={handleSubmit}
        className="btn btn-primary mt-3 align-self-end"
      >
        Save
      </button>
    </div>
  );
};
