import { useEffect, useState } from "react";
import { FaGripHorizontal } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import { Tenant } from "../../models";
import { ENDPOINTS, getCurrentUser, httpService } from "../../services";
import Loader from "../../components/common/loader";

const SelectTenant = () => {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const navigate = useNavigate();
  const user = getCurrentUser();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async function fetchTenants() {
      const res = await httpService<Tenant>(ENDPOINTS.Tenants).getAll();
      setTenants(res.data ?? []);
      setLoading(false);
    })();
  }, []);

  const handleSelect = (tenant: Tenant) => {
    // Set tenant header
    localStorage.setItem(
      "tenant",
      JSON.stringify({ id: tenant?.id, name: tenant?.name, code: tenant?.code })
    );

    // Navigate to dashboard
    navigate("/dashboard", { replace: true });

    // Hard refresh
    // window.location.reload();
  };

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="d-flex flex-column align-items-center py-5">
      <div className="">
        <img src="/assets/img/logo.svg" width={150} height={150} alt="logo" />
      </div>

      {!loading && tenants.length > 0 ? (
        <div className="d-flex justify-items-between flex-wrap">
          {tenants
            .filter(
              (t) =>
                user &&
                user.tenantIDs
                  .split(",")
                  .map((t) => Number(t))
                  .includes(t.id)
            )
            .map((tenant) => {
              return (
                <div
                  className="d-flex flex-column align-items-center card p-5 mt-3 ml-3"
                  key={tenant.id}
                  onClick={() => handleSelect(tenant)}
                  style={{ cursor: "pointer" }}
                >
                  <FaGripHorizontal size="40px" color="red" />
                  <h5 className="mt-2"> {tenant.name}</h5>
                </div>
              );
            })}
        </div>
      ) : loading ? (
        <Loader />
      ) : (
        <span>No data</span>
      )}
    </div>
  );
};

export default SelectTenant;
