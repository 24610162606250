import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Activity, Department, ROLES } from "../../models";
import { ENDPOINTS, getCurrentUser, httpService } from "../../services";
import { useEffect, useState } from "react";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";

type Props = {
  activity: Activity;
  reload: boolean;
  setReload: (reload: boolean) => void;
};

export const UpdateActivity = ({ activity, reload, setReload }: Props) => {
  const [departments, setDepartments] = useState<Department[]>([]);
  const user = getCurrentUser();

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("Name is required"),
      departmentId: Yup.number().required("Department id is required"),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: activity.name,
      departmentId: activity.departmentId,
    },
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.Activities).update(activity.id, values);
    setReload(!reload);
    reset();
  };

  useEffect(() => {
    (async () => {
      const res = await httpService(ENDPOINTS.AllDepartments).getAll();
      setDepartments(res.data ?? []);
    })();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column mt-4"
    >
      <div className="form-group mb-4 d-flex flex-column align-items-start align-content-center mt-3">
        <input
          type="text"
          className="form-control"
          id="name"
          placeholder="Activity name"
          {...register("name")}
        />
        {/* <select
          {...register("departmentId")}
          id="departmentId"
          className="form-control mt-2"
          defaultValue={
            user?.roleName === ROLES.DIRECTOR ? +user.departmentId : 0
          }
          disabled={user?.roleName === ROLES.DIRECTOR}
        >
          <option selected>Select Department</option>
          {departments.map((d) => {
            return (
              <option
                key={d.id}
                value={d.id}
                selected={d.id === activity.departmentId}
              >
                {d.name}
              </option>
            );
          })}
        </select> */}
        <select
          className="form-control form-control-sm nested mt-2"
          {...register("departmentId")}
          id="departmentId"
        >
          <option value="">Select Section</option>
          {departments.map((d) => {
            return (
              <optgroup label={d.name} key={d.id}>
                {d.sections.map((s) => {
                  return (
                    <option
                      value={s.id}
                      key={s.id}
                      selected={d.id === activity.sectionId}
                    >
                      {toUpperCaseFirstLetter(s.name)}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        </select>
      </div>
      <button
        type="submit"
        className="btn btn-primary mt-3 d-flex align-items-center justify-content-center align-self-end"
      >
        {isSubmitting && (
          <span
            style={{ fontSize: "10px", textAlign: "center" }}
            className="spinner-border text-white mr-4"
          />
        )}
        <span>Update</span>
      </button>
    </form>
  );
};
