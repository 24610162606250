import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Footer from "../../components/layout/footer";
import { Department, TableHeader } from "../../models";
import { ENDPOINTS, httpService } from "../../services";
import DepartmentsTable from "./departmentsTable";
import Loader from "../../components/common/loader";
import { CreateDepartment } from "../../components/forms";

const headers: TableHeader[] = [
  {
    id: 1,
    name: "Unit Name",
  },
  {
    id: 2,
    name: "Created At",
  },
  {
    id: 3,
    name: "Actions",
  },
];
const Index = () => {
  const [departments, setDepartments] = useState<Department[]>([]);
  const toggle = withReactContent(Swal);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Fetch Sections
  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await httpService(ENDPOINTS.AllDepartments).getAll();
      setDepartments(res.data ?? []);
      setLoading(false);
    })();
  }, [reload]);

  const onCreateDepartment = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <CreateDepartment reload={reload} setReload={setReload} />,
    });
  };

  return (
    <div id="content" className="main-content">
      <div className="layout-px-spacing">
        <div className="row layout-top-spacing" id="cancel-row">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-6 mb-4">
              <div className="d-flex align-items-between align-content-center">
                <div style={{ flex: 1 }}>{/* left side */}</div>
                <div>
                  <button
                    className="btn btn-dark mr-2"
                    onClick={() => onCreateDepartment()}
                  >
                    New Department
                  </button>
                </div>
              </div>
              <div className="table-responsive mb-4 px-2 mt-4">
                {departments.length > 0 ? (
                  <DepartmentsTable
                    headers={headers}
                    departments={departments}
                    reload={reload}
                    setReload={setReload}
                    open={open}
                    setOpen={setOpen}
                  />
                ) : loading ? (
                  <Loader />
                ) : (
                  <span>No data</span>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
