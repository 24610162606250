const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section f-section-1">
        <p>
          Copyright © {new Date().getFullYear()}{" "}
          <a target="_blank" href="https://designreset.com">
            B.M.A
          </a>
          , All rights reserved.
        </p>
      </div>
      <div className="footer-section f-section-2">
        <p className="text-success">
          Meeting Minutes Version <span className="text-danger">1.0</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
