import {
  FaGripHorizontal,
  FaHome,
  FaListOl,
  FaTable,
  FaUsers,
} from "react-icons/fa";

import NavListItem from "./navListItem";

const liItems = [
  {
    currentPath: "/dashboard",

    children: <FaHome style={{ width: "20px", height: "23px" }} />,
  },
  {
    currentPath: "/dashboard/objectives",

    children: <FaListOl style={{ width: "20px", height: "23px" }} />,
  },
  {
    currentPath: "/dashboard/departments",

    children: <FaTable style={{ width: "20px", height: "23px" }} />,
  },
  {
    currentPath: "/dashboard/users",

    children: <FaUsers style={{ width: "20px", height: "23px" }} />,
  },
  {
    currentPath: "/dashboard/tenants",

    children: <FaGripHorizontal style={{ width: "20px", height: "23px" }} />,
  },
  ,
];

const NavBar = () => {
  return (
    <div className="main-container" id="container">
      <div className="overlay" />
      <div className="search-overlay" />
      <div className="topbar-nav header navbar" role="banner">
        <nav id="topbar">
          <ul className="list-unstyled menu-categories" id="topAccordion">
            {liItems.map((item: any) => {
              return (
                <NavListItem
                  currentPath={item.currentPath}
                  children={item.children}
                />
              );
            })}
          </ul>
          {/* <ul className="list-unstyled menu-categories" id="topAccordion">
            <li
              className={`${
                pathname === "/dashboard"
                  ? "menu single-menu active"
                  : "menu single-menu"
              }`}
            >
              <NavLink to="/dashboard" className="dropdown-toggle autodroprown">
                <div>
                  <FaHome style={{ width: "20px", height: "23px" }} />
                  <span>Dashboard</span>
                </div>
              </NavLink>
            </li>
            <li
              className={`${
                pathname === "/dashboard/objectives"
                  ? "menu single-menu active"
                  : "menu single-menu"
              }`}
            >
              <NavLink to="/dashboard/objectives">
                <div>
                  <FaListOl style={{ width: "20px", height: "23px" }} />
                  <span>Objectives</span>
                </div>
              </NavLink>
            </li>

            <li
              className={`${
                pathname === "/dashboard/departments"
                  ? "menu single-menu active"
                  : "menu single-menu"
              }`}
              style={{
                pointerEvents: `${
                  user &&
                  (user.roleName === "Editor" ||
                    user.roleName === "Admin" ||
                    user.roleName === "Super.Admin")
                    ? "auto"
                    : "none"
                }`,
                opacity: `${
                  user &&
                  (user.roleName === "Editor" ||
                    user.roleName === "Admin" ||
                    user.roleName === "Super.Admin")
                    ? 1
                    : 0.6
                }`,
              }}
            >
              <NavLink to="/dashboard/departments">
                <div>
                  <FaTable style={{ width: "20px", height: "23px" }} />
                  <span>Departments</span>
                </div>
              </NavLink>
            </li>
            <li
              className={`${
                pathname === "/dashboard/sections"
                  ? "menu single-menu active"
                  : "menu single-menu"
              }`}
              style={{
                pointerEvents: `${
                  user && user.roleName !== "Editor" ? "auto" : "none"
                }`,
                opacity: `${user && user.roleName !== "Editor" ? 1 : 0.6}`,
              }}
            >
              <NavLink to="/dashboard/sections">
                <div>
                  <FaGripHorizontal style={{ width: "20px", height: "23px" }} />
                  <span>Sections</span>
                </div>
              </NavLink>
            </li>
            <li
              className={`${
                pathname === "/dashboard/users"
                  ? "menu single-menu active"
                  : "menu single-menu"
              }`}
              style={{
                pointerEvents: `${
                  user &&
                  (user.roleName === "Admin" || user.roleName === "Super.Admin")
                    ? "auto"
                    : "none"
                }`,
                opacity: `${
                  user &&
                  (user.roleName === "Admin" || user.roleName === "Super.Admin")
                    ? 1
                    : 0.6
                }`,
              }}
            >
              <NavLink to="/dashboard/users">
                <div>
                  <FaUsers style={{ width: "20px", height: "23px" }} />
                  <span>Users</span>
                </div>
              </NavLink>
            </li>
            <li
              className={`${
                pathname === "/dashboard/employees"
                  ? "menu single-menu active"
                  : "menu single-menu"
              }`}
              style={{
                pointerEvents: `${
                  user && user.roleName !== "Editor" ? "auto" : "none"
                }`,
                opacity: `${user && user.roleName !== "Editor" ? 1 : 0.6}`,
              }}
            >
              <NavLink to="/dashboard/employees">
                <div>
                  <FaUsers style={{ width: "20px", height: "23px" }} />
                  <span>Employees</span>
                </div>
              </NavLink>
            </li>
            <li
              className={`${
                pathname === "/dashboard/tenants"
                  ? "menu single-menu active"
                  : "menu single-menu disabled"
              }`}
              style={{
                pointerEvents: `${
                  user && user.roleName === "Super.Admin" ? "auto" : "none"
                }`,
                opacity: `${user && user.roleName !== "Super.Admin" ? 0.6 : 1}`,
              }}
            >
              <NavLink to="/dashboard/tenants">
                <div>
                  <FaGripHorizontal style={{ width: "20px", height: "23px" }} />
                  <span>Tenants</span>
                </div>
              </NavLink>
            </li>
          </ul> */}
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
