import moment from "moment";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SureToDelete from "../../components/common/SureToDelete";
import { UpdateTenant } from "../../components/forms";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { TableHeader, Tenant } from "../../models";
import { ENDPOINTS, httpService } from "../../services";

type Props = {
  headers: TableHeader[];
  tenants: Tenant[];
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const TenantsTable = ({ headers, tenants, reload, setReload }: Props) => {
  const toggle = withReactContent(Swal);

  const onEdit = (tenant: Tenant) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: (
        <UpdateTenant tenant={tenant} reload={reload} setReload={setReload} />
      ),
    });
  };

  const handleDelete = async (id: number) => {
    await httpService(ENDPOINTS.Tenants).delete(id);
    setReload(!reload);
  };

  const onDelete = (id: number) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <SureToDelete id={id} onDelete={handleDelete} />,
    });
  };

  return (
    <table className="table ">
      <thead>
        <tr>
          {headers.map((h) => (
            <th className="text-left" key={h.id}>
              {h.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tenants.map((tenant) => {
          return (
            <tr key={tenant.id}>
              <td>{toUpperCaseFirstLetter(tenant.name)}</td>

              <td>{moment(tenant.createdAt).format("ll")}</td>
              <td>
                <button
                  className="btn btn-primary mr-2"
                  onClick={() => onEdit(tenant)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger mr-2"
                  onClick={() => onDelete(tenant.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TenantsTable;
