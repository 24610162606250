export enum ENDPOINTS {
  DepartmentTasks = "objectives/departmentTasks",
  MeetingObjectives = "meetings/objectives",
  Objectives = "objectives",
  Search = "objectives/search",
  Activities = "activities",
  Departments = "departments",
  AllDepartments = "departments/all",
  Meetings = "meetings",
  Auth = "auth/login",
  Tenants = "tenants",
  Users = "users",
  UpdatePassword = "users/password",
  Roles = "roles",
  Report = "meetings/report",
  RescheduleActivity = "activities/reschedule",
  ActivitiesStats = "meetings/activities/stats",
  ActivitiesByDepartment = "activities/activitiesByDepartment",
  SubmitActivity = "activities/submit",
  AddActivities = "objectives/addActivities",
  Sections = "sections",
  AssignUserToTenant = "users/assign",
  AssignDepartmentToTenant = "departments/assign",
  SendEmailActivities = "activities/sendEmail",
  Employees = "employees",
  EmployeeWorkPlans = "employees/createWorkPlans",
  SectionUnits = "sections/Units",
  UpdateJobDescription = "employees/UpdateJobDescription",
  DeleteEmployeeWorkPlan = "employees/deleteWorkPlan",
  UpdateEmployeeWorkPlanProgress = "employees/updateEmployeeWorkPlanProgress",
  UpdateEmployeeWorkPlan = "employees/updateEmployeeWorkPlan",
  AddSectionUnits = "sections/addSectionUnit",
  UnitEmployees = "employees/unitEmployees",
  AddDepartmentSections = "departments/addSections",
  DeleteSection = "departments/deleteSection",
  UpdateSection = "departments/updateSection",
}
