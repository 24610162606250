import axios from "axios";
import Swal from "sweetalert2";
import { ENDPOINTS } from "./endpoints";
import { PagingOptions } from "./internal";
import jwtDecode from "jwt-decode";
import { CurrentUser, Tenant } from "../models";

export let BASE_URL = "";

if (process.env.NODE_ENV !== "development") {
  BASE_URL = "https://minutes.slmof.org/api/api/";
} else {
  BASE_URL = "https://localhost:7200/api/";
}

export const TOKEN = "token";
export const TENANT = "tenant";

export const httpService = <T>(
  endpoints: ENDPOINTS,
  options?: PagingOptions | URLSearchParams
) => {
  const query = options ?? new PagingOptions();

  const formattedQuery =
    query instanceof PagingOptions ? query.format() : query.toString();

  let url = BASE_URL + endpoints;

  requestInterceptors();
  responseInterceptors();

  return {
    getAll: () => axios.get(`${url}?${formattedQuery}`),
    post: (newData: T) => axios.post(url, newData),
    delete: (id: number) => axios.delete(`${url}/${id}`),
    update: (id: number, updatedRecord: T) =>
      axios.put(url + "/" + id, updatedRecord),
    getById: (id: number) => axios.get(`${url}/${id}?${formattedQuery}`),
  };
};

const requestInterceptors = () => {
  axios.interceptors.request.use((request: any) => {
    const token = localStorage.getItem(TOKEN);
    const tenant = JSON.parse(localStorage.getItem(TENANT) ?? "{}") as Tenant;
    const isLoggedIn = token ? true : false; // check local storage and return true if token is there
    const isApiUrl = request?.url?.startsWith(BASE_URL);

    if (isLoggedIn && isApiUrl && tenant) {
      request!.headers!.common!.Authorization = `Bearer ${token}`;
      request!.headers!.common!.tenantId = tenant.id;
    }

    return request;
  });
};

const responseInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      if (response.status === 201) {
        Swal.fire({
          toast: true,
          position: "top-end",
          timer: 2000,
          icon: "success",
          showConfirmButton: false,
          text: "The record has been added successfully.",
        });
      }
      if (response.status === 204) {
        Swal.fire({
          toast: true,
          position: "top-end",
          timer: 2000,
          icon: "success",
          showConfirmButton: false,
          text: "The record has been modified successfully.",
        });
      }
      return response;
    },
    (error) => {
      if (!error.response || error.response.status === 0) {
        Swal.fire({
          toast: true,
          position: "top-end",
          timer: 2000,
          icon: "error",
          showConfirmButton: false,
          text: "Unable to reach the server.",
        });
      }
      if (error.response.status === 400) {
        Swal.fire({
          toast: true,
          position: "top-end",
          timer: 2000,
          icon: "error",
          showConfirmButton: false,
          text: "Complete the data.",
        });
      }
      if (error.response.status === 500) {
        Swal.fire({
          toast: true,
          position: "top-end",
          timer: 2000,
          icon: "error",
          showConfirmButton: false,
          text: "Something went wrong. Please try again.",
        });
      }

      if (error.response.status === 403) {
        Swal.fire({
          toast: true,
          position: "top-end",
          timer: 2000,
          icon: "error",
          showConfirmButton: false,
          text: "Forbidden",
        });
      }

      if (error.response.status === 404) {
        Swal.fire({
          toast: true,
          position: "top-end",
          timer: 2000,
          icon: "error",
          showConfirmButton: false,
          text: "Notfound.",
        });
      }

      if (error.response.status === 401) {
        const tenant = JSON.parse(
          localStorage.getItem(TENANT) ?? "{}"
        ) as Tenant;

        let message = "You're not authorized to access ";

        Swal.fire({
          toast: true,
          position: "top-end",
          timer: 2000,
          icon: "error",
          showConfirmButton: false,
          text:
            `${error.response.data.message} ${
              error.response.data.message === message ? tenant?.name : ""
            }` ?? "Unauthorized",
        });
      }

      const token = localStorage.getItem(TOKEN);
      const isLoggedIn = token ? true : false; // check local storage and return true if token is there
      if (error.response.status === 401 && isLoggedIn) {
        //inform user that their session is ended with Swal alert.
        logout();
      }
      return error;
    }
  );
};

export const logout = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(TENANT);
};

export const getCurrentUser = (): CurrentUser | null => {
  const jwt = localStorage.getItem(TOKEN);
  return !jwt ? null : jwtDecode(jwt);
};

export const getCurrentTenant = (): {
  id: string;
  name: string;
  code: string;
} | null => {
  const tenant = localStorage.getItem(TENANT);
  return tenant !== null ? JSON.parse(tenant) : null;
};
