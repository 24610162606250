import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CreateRole, CreateUser } from "../../components/forms";
import Footer from "../../components/layout/footer";
import { TableHeader, User } from "../../models";
import {
  ENDPOINTS,
  PaginatedResult,
  PagingOptions,
  getCurrentUser,
  httpService,
} from "../../services";
import UsersTable from "./usersTable";
import Loader from "../../components/common/loader";

const headers: TableHeader[] = [
  {
    id: 1,
    name: "Full Name",
  },
  {
    id: 2,
    name: "Section",
  },
  {
    id: 3,
    name: "Role",
  },
  {
    id: 4,
    name: "Created At",
  },
  {
    id: 5,
    name: "Actions",
  },
];
const Index = () => {
  const [users, setUsers] = useState<User[]>([]);
  const user = getCurrentUser();
  const toggle = withReactContent(Swal);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  // Fetch Users
  useEffect(() => {
    setLoading(true);
    (async () => {
      const options = new PagingOptions(page, perPage);

      const res = await httpService(ENDPOINTS.Users, options).getAll();

      const {
        items,
        page: currentPage,
        size,
        totalPages,
      } = res.data as PaginatedResult<User>;

      setUsers(items ?? []);
      setPage(currentPage);
      setPerPage(size);
      setTotalPages(totalPages);
      setLoading(false);
    })();
  }, [page, perPage, reload]);

  const onCreateUser = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "50%",
      html: <CreateUser reload={reload} setReload={setReload} />,
    });
  };
  const onCreateRole = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "50%",
      html: <CreateRole />,
    });
  };
  return (
    <div id="content" className="main-content">
      <div className="layout-px-spacing">
        <div className="row layout-top-spacing" id="cancel-row">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-6">
              <div className="d-flex align-items-space-between align-centent-center">
                <div style={{ flex: 1 }}>{/* left side */}</div>
                {(user?.roleName === "Admin" ||
                  user?.roleName === "Super.Admin") && (
                  <>
                    <div>
                      <button
                        className="btn btn-dark mr-2"
                        onClick={() => onCreateRole()}
                      >
                        New Role
                      </button>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary mr-2"
                        onClick={() => onCreateUser()}
                      >
                        New User
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="table-responsive mb-4 px-2 mt-4">
                {users.length > 0 ? (
                  <UsersTable
                    page={page}
                    perPage={perPage}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    totalPages={totalPages}
                    headers={headers}
                    users={users}
                    reload={reload}
                    setReload={setReload}
                  />
                ) : loading ? (
                  <Loader />
                ) : (
                  <span>No data</span>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
