import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Meeting, Message } from "../../models";
import { ENDPOINTS, httpService } from "../../services";
import { useEffect, useState } from "react";

const SendEmail = () => {
  const [meetings, setMeetings] = useState<Meeting[]>([]);

  const validationSchema = Yup.object()
    .shape({
      to: Yup.string().required("To is required"),
      subject: Yup.string().required("Subject is required"),
      body: Yup.string().required("Body is required"),
      meetingId: Yup.number().required("Meeting id is required"),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm(formOptions);

  useEffect(() => {
    (async () => {
      const res = await httpService(ENDPOINTS.Meetings).getAll();
      setMeetings((res && res.data && res.data) ?? []);
    })();
  }, []);

  const handleOnSubmit = async (values: any) => {
    let message = new Message();

    message.to = values.to?.replace(/\s/g, "").split(",");
    message.subject = values.subject;
    message.content = values.body;
    message.meetingId = values.meetingId;

    await httpService(ENDPOINTS.SendEmailActivities).post(message);
    reset();
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column mt-5"
    >
      <div className="form-group mb-4 d-flex flex-column align-items-start align-content-center">
        <select
          {...register(`meetingId`)}
          id="departmentId"
          className="form-control"
        >
          <option>Select meeting</option>
          {meetings.map((m) => {
            return (
              <option key={m.id} value={m.id}>
                {m.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="form-group mb-4 d-flex flex-column align-items-start align-content-center">
        <input
          type="text"
          className="form-control"
          id="inputTo"
          placeholder="To"
          {...register("to")}
          required
        />
      </div>
      <div className="form-group mb-4 d-flex flex-column align-items-start align-content-center">
        <input
          type="text"
          className="form-control"
          id="inputSubject"
          placeholder="Subject"
          {...register("subject")}
        />
      </div>
      <div className="form-group mb-4 d-flex flex-column align-items-start align-content-center">
        <textarea
          style={{ height: 200 }}
          className="form-control"
          id="inputBody"
          placeholder="Body"
          {...register("body")}
        />
      </div>
      <button
        type="submit"
        className="btn btn-primary mt-3 d-flex align-items-center justify-content-center"
      >
        {isSubmitting && (
          <span
            style={{ fontSize: "10px", textAlign: "center" }}
            className="spinner-border text-white mr-4"
          />
        )}
        <span>Send</span>
      </button>
    </form>
  );
};

export default SendEmail;
