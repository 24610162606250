import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SureToDelete from "../../components/common/SureToDelete";
import {
  AssignUserToTenant,
  UpdatePassword,
  UpdateUser,
} from "../../components/forms";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { TableHeader, User } from "../../models";
import { ENDPOINTS, getCurrentUser, httpService } from "../../services";
import Paginate from "../../components/common/paginate";

type Props = {
  headers: TableHeader[];
  users: User[];
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  perPage: number;
  totalPages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
};

const UsersTable = ({
  headers,
  users,
  reload,
  page,
  perPage,
  totalPages,
  setPage,
  setPerPage,
  setReload,
}: Props) => {
  const toggle = withReactContent(Swal);
  const currentUser = getCurrentUser();

  const onEdit = (user: User) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <UpdateUser user={user} reload={reload} setReload={setReload} />,
    });
  };

  const handleDelete = async (id: number) => {
    await httpService(ENDPOINTS.Users).delete(id);
    setReload(!reload);
  };

  const onDelete = (id: number) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <SureToDelete id={id} onDelete={handleDelete} />,
    });
  };

  const onAssign = (user: User) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <AssignUserToTenant user={user} />,
    });
  };

  const onResetPassword = (user: User) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <UpdatePassword user={{ id: user.id, userName: user.userName }} />,
    });
  };

  return (
    <>
      <table className="table ">
        <thead>
          <tr>
            {headers.map((h) => (
              <th
                className={`${h.name === "Actions" ? "text-center" : ""}`}
                key={h.id}
              >
                {h.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            return (
              <tr key={user.id}>
                <td>
                  {`${toUpperCaseFirstLetter(user.firstName)}
                    ${toUpperCaseFirstLetter(user.lastName)}`}
                </td>

                <td>{toUpperCaseFirstLetter(user.section?.name ?? "")}</td>

                <td>{toUpperCaseFirstLetter(user?.role?.name ?? "")}</td>
                <td>{moment(user.createdAt).format("ll")}</td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-primary mr-2"
                    onClick={() => onEdit(user)}
                  >
                    Edit
                  </button>
                  {(currentUser?.roleName === "Admin" ||
                    currentUser?.roleName === "Super.Admin") && (
                    <button
                      className="btn btn-outline-warning mr-2"
                      onClick={() => onResetPassword(user)}
                    >
                      Reset
                    </button>
                  )}
                  <button
                    className="btn btn-outline-secondary mr-2"
                    onClick={() => onAssign(user)}
                  >
                    Assign
                  </button>
                  {currentUser?.roleName === "Super.Admin" && (
                    <button
                      className="btn btn-outline-danger r mr-2"
                      onClick={() => onDelete(user.id)}
                      disabled={currentUser?.roleName !== "Super.Admin"}
                    >
                      Delete
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="w-50">
        <Paginate
          canPreviousPage={page > 0 && true}
          canNextPage={totalPages !== page && true}
          currentPage={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          totalPage={totalPages}
        />
      </div>
    </>
  );
};

export default UsersTable;
