

export class PaginatedResult<T> {
    page: number = TableDefaults.page;
    size: number = TableDefaults.size;
    items: T[] = [];
    totalItems: number = 0;
    totalPages: number = 0;
}

export enum TableDefaults {
    page = 0,
    size = 25
}