export class Role {
  id: number = 0;
  name: string = "";
  permissions: string[] = [];
}
export class PermissionList {
  title: string = "";
  permissions: { title: string; value: string }[] = [];
}

export enum RoleType {
  SuperAdmin = "Super.Admin",
  Admin = "Admin",
  Director = "Director",
  Editor = "Editor",
}
