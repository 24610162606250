import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { ActivityStats, TenantCode } from "../../models";
import { getCurrentTenant } from "../../services";

ChartJS.register(ArcElement, Tooltip, Legend);
const tenant = getCurrentTenant();

const options = {
  plugins: {
    title: {
      display: true,
      text: "Activities Progress Chart",
    },
  },
};

type Props = {
  stats: ActivityStats;
};

function calculatePercentage(actionStats: ActivityStats, status: number) {
  const totalTasks =
    actionStats.zeroPercentActions +
    actionStats.twentyFivePercentActions +
    actionStats.fiftyPercentActions +
    actionStats.seventyFivePercentActions +
    actionStats.hundredPercentActions;
  return `${((status / totalTasks) * 100).toFixed()}%`;
}

export default function ActivityChart({ stats }: Props) {
  const tasksInProgress =
    stats?.fiftyPercentActions +
    stats?.seventyFivePercentActions +
    stats?.twentyFivePercentActions;

  const data = {
    labels: [
      `Not Started ${calculatePercentage(stats, stats.zeroPercentActions)}`,
      `In Progress ${calculatePercentage(stats, tasksInProgress)}`,
      `Completed ${calculatePercentage(stats, stats.hundredPercentActions)}`,
    ],
    datasets: [
      {
        label: "# of Activities",
        backgroundColor: ["#cc1d1d", "#f5ed05", "#0ba360"],
        data: [
          stats?.zeroPercentActions,
          tasksInProgress,
          stats?.hundredPercentActions,
        ],
      },
    ],
  };
  return (
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 layout-spacing ">
      <div className="widget-four">
        <div className="row layout-top-spacing m-0 p-0">
          <Pie data={data} options={options} />
        </div>
      </div>
    </div>
  );
}
