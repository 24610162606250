import { useFieldArray, useForm } from "react-hook-form";
import { ENDPOINTS, httpService } from "../../services";
import { useEffect, useState } from "react";
import { Tenant, User } from "../../models";
import Loader from "../common/loader";

type Props = {
  user: User;
};

export const AssignUserToTenant = ({ user }: Props) => {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [tenantIDs, setTenantIDs] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async function fetchTenants() {
      const res = await httpService(ENDPOINTS.Tenants).getAll();
      setTenants(res.data ?? []);

      setTenantIDs(
        res.data && res.data.length > 0 && res.data.map((t: Tenant) => t.id)
      );

      reset({ tenants: res.data ?? [] });
      setLoading(false);
    })();
  }, []);

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm();

  const { fields } = useFieldArray({
    control,
    name: "tenants", // unique name for the Field Array
  });

  const handleOnSubmit = async () => {
    await httpService(ENDPOINTS.AssignUserToTenant).update(user.id, {
      tenantIDs,
    });
  };

  const onChangeTenantId = (id: number) => {
    if (tenantIDs.includes(id)) {
      setTenantIDs((prev) => prev.filter((t) => t !== id));
    } else {
      setTenantIDs((prev) => [...prev, id]);
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="d-flex flex-column mt-4"
      >
        <div className="form-group d-flex flex-column align-items-start">
          {fields.map((field, idx) => {
            return (
              <div
                className="n-chk my-1 ds-flex flex-column"
                key={tenants[idx].id}
              >
                <label className="new-control new-checkbox checkbox-outline-default">
                  <input
                    type="checkbox"
                    value={tenants[idx].id}
                    {...register(`tenants.${idx}.id`, {
                      onChange: () => onChangeTenantId(tenants[idx].id),
                    })}
                    className="new-control-input ml-3"
                    name={`tenants.${idx}.id`}
                  />
                  <span className="new-control-indicator mr-2"></span>
                  {tenants[idx].name}
                </label>
              </div>
            );
          })}
        </div>
        <button
          type="submit"
          className="btn btn-primary mt-3 d-flex align-items-center justify-content-center align-self-end"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          <span>Update</span>
        </button>
      </form>
    </>
  );
};
