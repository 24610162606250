import { Helmet } from "react-helmet";
import { getCurrentUser } from "../../services";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { UpdatePassword } from "../../components/forms";

const Profile = () => {
  const user = getCurrentUser();
  const toggle = withReactContent(Swal);

  const onToggle = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: (
        <UpdatePassword
          user={{ id: user?.id ?? 0, userName: user?.username ?? "" }}
        />
      ),
    });
  };

  return (
    <>
      <Helmet>
        <link
          href="/assets/css/users/user-profile.css"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="row layout-spacing">
            <div className="col-xl-4 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
              <div className="user-profile layout-spacing">
                <div className="widget-content widget-content-area">
                  <div className="text-center user-info">
                    <p>{user && user.fullName}</p>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => onToggle()}
                    >
                      Change UserName/Password
                    </button>
                  </div>
                  <div className="user-info-list">
                    <div>
                      <ul className="contacts-block list-unstyled">
                        <li className="d-flex align-items-start justify-content-between">
                          <span>Role:</span>
                          <span>{user && user.roleName}</span>
                        </li>
                        <li className="d-flex align-items-start justify-content-between">
                          <span className="mr-5">Department:</span>
                          <span>{(user && user.sectionName) ?? ""}</span>
                        </li>
                        <li className="contacts-block__item">
                          <li className="d-flex align-items-start justify-content-between">
                            <span>Username:</span>
                            <span>{user && user.username}</span>
                          </li>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
