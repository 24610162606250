import DatePicker from "react-datepicker";

interface Props {
  fromDate: Date | undefined;
  toDate: Date | undefined;
  setFromDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setToDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

const FilterByDate = ({ fromDate, toDate, setFromDate, setToDate }: Props) => {
  return (
    <div className="form-group d-flex flex-column align-content-start align-items-start">
      <div className="d-flex">
        <span className="mr-2">
          <DatePicker
            className="form-control"
            placeholderText="Start date"
            selected={fromDate}
            onChange={(date: Date) => setFromDate(date)}
          />
        </span>
        <span>
          <DatePicker
            className="form-control"
            placeholderText="End date"
            selected={toDate}
            onChange={(date: Date) => setToDate(date)}
          />
        </span>
      </div>
    </div>
  );
};

export default FilterByDate;
