import { Navigate, Route, Routes } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import RequiredAuth from "./auth/requiredAuth";
import Dashboard from "./pages";
import Departments from "./pages/departments";
import Login from "./pages/login";
import Objectives from "./pages/objectives";
import Home from "./pages/home";
import Users from "./pages/users";
import Tenants from "./pages/tenants";
import Profile from "./pages/profile";
import NewGanttReport from "./reports/newGanttReport";
import SelectTenant from "./pages/home/selectTenant";
import ActivityReport from "./reports/activityReport";
import RequiredPermission from "./auth/requiredPermission";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/tenants" element={<SelectTenant />} />
        <Route
          path="dashboard"
          element={
            <RequiredAuth>
              <Dashboard />
            </RequiredAuth>
          }
        >
          <Route path="/dashboard" element={<Home />} />
          <Route
            path="objectives"
            element={
              <RequiredPermission path="objectives">
                <Objectives />
              </RequiredPermission>
            }
          />
          <Route
            path="users"
            element={
              <RequiredPermission path="users">
                <Users />
              </RequiredPermission>
            }
          />
          <Route
            path="departments"
            element={
              <RequiredPermission path="departments">
                <Departments />
              </RequiredPermission>
            }
          />
          <Route path="meetings" element={<h1>Meetings page</h1>} />
          <Route
            path="tenants"
            element={
              <RequiredPermission path="tenants">
                <Tenants />
              </RequiredPermission>
            }
          />
          <Route path="profile" element={<Profile />} />
          <Route path="ganttReport" element={<NewGanttReport />} />
          <Route path="activityReport" element={<ActivityReport />} />
        </Route>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<h1>Not found page</h1>} />
      </Routes>
    </div>
  );
}

export default App;
