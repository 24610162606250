import { Department } from "./Department";
import { Objective } from "./Objective";
import { Reschedule } from "./Reschedule";
import { Section } from "./Section";

export class Activity {
  id: number = 0;
  name: string = "";
  departmentId: number = 0;
  startDate: string = "";
  endDate?: string;
  objective?: Objective;
  objectiveId: number = 0;
  section?: Section;
  sectionId: number = 0;
  department?: Department;
  progress: string = "";
  updatedAt: string = "";
  createdAt: string = "";
  reschedules: Reschedule[] = [];
  duration: number = 0;
  remark: string = "";
}

export class ActivitiesByDepartment {
  name: string = "";
  departmentId: number = 0;
  zero: number = 0;
  twentyFive: number = 0;
  fifty: number = 0;
  seventyFive: number = 0;
  hundred: number = 0;
  total: number = 0;
}
export class ActivityStats {
  zeroPercentActions: number = 0;
  twentyFivePercentActions: number = 0;
  fiftyPercentActions: number = 0;
  seventyFivePercentActions: number = 0;
  hundredPercentActions: number = 0;
}
export class SubmitActivity {
  progress: string = "";
  remark: string = "";
}
