import moment from "moment";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { FaPrint } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Objective, TableHeader } from "../models";

const headers: TableHeader[] = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "Name",
  },
  {
    id: 3,
    name: "Start Date",
  },
  {
    id: 4,
    name: "End Date",
  },
  {
    id: 5,
    name: "Department",
  },
  {
    id: 6,
    name: "Duration",
  },
  {
    id: 7,
    name: "Progress",
  },
];

type Params = {
  objectives: Objective[];
};

function getProgress(progress: string) {
  if (+progress.slice(0, -1) === 0) {
    return "Not Completed";
  } else if (
    +progress.slice(0, -1) === 25 ||
    +progress.slice(0, -1) === 50 ||
    +progress.slice(0, -1) === 75
  ) {
    return "In Progress";
  } else {
    return "Completed";
  }
}

function getColor(progress: string) {
  if (+progress.slice(0, -1) === 0) {
    return "danger";
  } else if (
    +progress.slice(0, -1) === 25 ||
    +progress.slice(0, -1) === 50 ||
    +progress.slice(0, -1) === 75
  ) {
    return "warning";
  } else {
    return "success";
  }
}

const ActivityReport = () => {
  const { state } = useLocation();
  const params = state as Params;
  const componentRef = useRef(null);
  const [quarter, setQuarter] = useState<string>();
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    (function applyQuarterFilter() {
      if (quarter && quarter.length > 0) {
        console.log("effect one");

        // Deep copying
        let clonedObj = JSON.parse(
          JSON.stringify(params.objectives)
        ) as Objective[];

        let result = clonedObj
          .map((obj) => {
            obj.activities = obj.activities.filter((ac) => {
              if (
                quarterFilter(
                  moment(ac.startDate).month(),
                  moment(ac.endDate).month(),
                  quarter
                )
              )
                return ac;
              // return null;
            });

            return obj;
          })
          .filter((r) => r.activities.length > 0);

        setObjectives(result);
      } else {
        // Deep copying
        let clonedObj = JSON.parse(
          JSON.stringify(params.objectives)
        ) as Objective[];

        setObjectives(clonedObj);
      }
    })();
  }, [quarter]);

  useEffect(() => {
    (async function applyMonthFilter() {
      if (date !== null) {
        // Deep copying
        let clonedObj = JSON.parse(
          JSON.stringify(params.objectives)
        ) as Objective[];

        let result = clonedObj
          .map((obj) => {
            obj.activities = obj.activities.filter((ac) => {
              if (
                monthFilter(
                  moment(ac.startDate).month(),
                  moment(ac.endDate).month(),
                  moment(date).month()
                )
              )
                return ac;
              // return null;
            });

            return obj;
          })
          .filter((r) => r.activities.length > 0);

        setObjectives(result);
      }
    })();
  }, [date]);

  function quarterFilter(startMonth: number, endMonth: number, q: string) {
    if (q === "Q1" && endMonth <= 2) {
      return true;
    } else if (q === "Q2" && endMonth > 2 && endMonth <= 5) {
      return true;
    } else if (q === "Q3" && endMonth > 5 && endMonth <= 8) {
      return true;
    } else if (q === "Q4" && startMonth > 8 && endMonth <= 11) {
      return true;
    } else if (q === "First Six Months" && startMonth >= 0 && endMonth <= 5) {
      return true;
    } else if (q === "Last Six Months" && startMonth > 5 && endMonth <= 11) {
      return true;
    } else if (q === "Year" && startMonth === 0 && endMonth === 11) {
      return true;
    }
    return false;
  }

  function monthFilter(start: number, end: number, month: number) {
    if (start === month && end === month) {
      return true;
    }

    return false;
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="mx-4 my-4">
      <div className="d-flex justify-content-center">
        <select
          onChange={(e) => {
            setDate(null);
            setQuarter(e.target.value);
          }}
          className="form-control"
          style={{ width: "15%" }}
        >
          <option value="">Filter by quarterly</option>
          <option value="Q1">Q1</option>
          <option value="Q2">Q2</option>
          <option value="Q3">Q3</option>
          <option value="Q4">Q4</option>
          <option value="First Six Months">First Six Months</option>
          <option value="Last Six Months">Last Six Months</option>
          <option value="Year">Year</option>
        </select>

        <div className="ml-2">
          <DatePicker
            className="form-control"
            placeholderText="Filter by month"
            selected={date}
            onChange={(e) => {
              setQuarter(undefined);
              setDate(e);
            }}
            showMonthYearPicker
            dateFormat="MM"
          />
        </div>

        <div className="ml-2 align-self-center">
          <button
            className="btn btn-primary"
            onClick={handlePrint}
            disabled={objectives.length ? false : true}
          >
            <FaPrint /> Print
          </button>
        </div>
      </div>
      <div ref={componentRef} className="mt-4">
        <div className="d-flex flex-column align-items-center">
          <img src="/assets/img/logo.png" width={150} height={150} alt="logo" />
          <h4 className="mt-3">
            Report: Objectives/Activities
            {quarter !== undefined ? (
              <span className="card p-2">{quarter}</span>
            ) : (
              date !== null && (
                <span className="card p-1 mt-2">
                  {moment(date)
                    .month(date?.getMonth() ?? "")
                    .format("MMMM")}
                </span>
              )
            )}
          </h4>
        </div>
        {objectives.map((obj) => {
          return (
            <div className="card mt-3">
              <div className="p-2" style={{ background: "#964000" }}>
                <h5 style={{ color: "#fff" }}>{obj.topic}</h5>
              </div>
              <div className="p-1" style={{ background: "#C49102" }}>
                <h5 style={{ color: "#fff" }}>Activities</h5>
              </div>
              <table className="table mb-4">
                <thead>
                  <tr>
                    {headers.map((h) => (
                      <th key={h.id}>{h.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {obj.activities.map((ac, idx: number) => {
                    let startDate = moment(ac.startDate ?? "");
                    let endDate = moment(ac.endDate ?? "");
                    return (
                      <tr>
                        <td className="text-center">{idx + 1}</td>
                        <td className="">{ac.name}</td>
                        <td>{startDate.format("l")}</td>
                        <td>{endDate.format("l")}</td>
                        <td>{ac.department?.name}</td>
                        <td>
                          {`${moment.duration(endDate.diff(startDate)).asDays()}
                        Days`}
                        </td>
                        <td className="">
                          <span
                            className={`shadow-none badge outline-badge-${getColor(
                              ac.progress
                            )}`}
                          >
                            {getProgress(ac.progress)}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ActivityReport;
