import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  GetUserWithEndPoints,
  toUpperCaseFirstLetter,
} from "../../helpers/fnHelpers";

interface Props {
  children: React.ReactNode;
  currentPath: string;
}
const NavListItem = ({ children, currentPath }: Props) => {
  const user = GetUserWithEndPoints();

  const { pathname } = useLocation();
  const currentPathname =
    currentPath.split("/")[currentPath.split("/").length - 1];

  return (
    <li
      className={`menu single-menu ${pathname === currentPath ? "active" : ""}`}
      style={{
        pointerEvents: `${
          user.endPoints.includes(currentPathname) ? "auto" : "none"
        }`,
        opacity: `${user.endPoints.includes(currentPathname) ? 1 : 0.6}`,
      }}
    >
      <NavLink to={currentPath}>
        <div>
          {children}
          <span>{toUpperCaseFirstLetter(currentPathname)}</span>
        </div>
      </NavLink>
    </li>
  );
};

export default NavListItem;
