import { useEffect, useState } from "react";
import moment from "moment";
import { Meeting } from "../../models";
import { ENDPOINTS, httpService } from "../../services";

type Props = {
  onHandleChange: (value: any) => void;
};

const FilterObjectivesByMeeting = ({ onHandleChange }: Props) => {
  const [meetings, setMeetings] = useState<Meeting[]>([]);

  const lastMeetingId = meetings.length && meetings[0].id;

  const onChange = (e: any) => {
    onHandleChange(e.target.value);
  };

  useEffect(() => {
    (async function fetchMeetings() {
      const res = await httpService(ENDPOINTS.Meetings).getAll();

      setMeetings(res.data ?? []);
    })();
  }, []);

  return (
    <div className="form-group mr-2 " style={{ width: "20%" }}>
      <div className="form-group">
        <select
          id="meetingId"
          className="form-control form-control-sm"
          onChange={onChange}
          defaultValue={lastMeetingId}
        >
          {meetings.map((m) => {
            return (
              <option key={m.id} value={m.id}>
                {m.name} ({moment(m.date).format("ll")})
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default FilterObjectivesByMeeting;
