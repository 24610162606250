import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ENDPOINTS, getCurrentUser, httpService } from "../../services";
import { Department, Role, User } from "../../models";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { useEffect, useState } from "react";

type Props = {
  user: User;
  reload: boolean;
  setReload: (reload: boolean) => void;
};

export const UpdateUser = ({ user, reload, setReload }: Props) => {
  const [departments, setDepartments] = useState<Department[]>([]);

  const [roles, setRoles] = useState<Role[]>([]);

  const validationSchema = Yup.object()
    .shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      roleId: Yup.number().required("Role id is required"),
      sectionId: Yup.number().required("Section id is required"),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      roleId: user.role?.id,
      sectionId: user?.department?.id,
    },
  };
  // Fetch Departments and Roles
  useEffect(() => {
    (async function fetchDepartments() {
      const res = await httpService(ENDPOINTS.AllDepartments).getAll();
      setDepartments(res.data ?? []);
    })();

    (async function fetchRoles() {
      const res = await httpService(ENDPOINTS.Roles).getAll();
      setRoles(res.data ?? []);
    })();
  }, []);

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.Users).update(user.id, values);
    setReload(!reload);
    reset();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="d-flex flex-column mt-4"
      >
        <div className="d-flex align-content-center">
          <div className="form-group w-100 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="First name"
              {...register("firstName")}
            />
          </div>
          <div className="form-group ml-2 w-100 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Last name"
              {...register("lastName")}
            />
          </div>
        </div>
        <div className="d-flex align-content-center">
          <div className="form-group w-100 mb-4 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="sectionId">Section</label>
            {/* <select
              {...register("sectionId")}
              id="sectionId"
              className="form-control"
            >
              {departments.map((d) => {
                return (
                  <option
                    key={d.id}
                    value={d.id}
                    selected={d.id === user?.department?.id}
                  >
                    {d.name}
                  </option>
                );
              })}
            </select> */}

            <select
              className="form-control form-control-sm nested"
              {...register("sectionId")}
              id="sectionId"
            >
              <option value="">Select Section</option>
              {departments.map((d) => {
                return (
                  <optgroup label={d.name} key={d.id}>
                    {d.sections.map((s) => {
                      return (
                        <option
                          value={s.id}
                          key={s.id}
                          selected={s.id === user.section?.id}
                        >
                          {toUpperCaseFirstLetter(s.name)}
                        </option>
                      );
                    })}
                  </optgroup>
                );
              })}
            </select>
          </div>
          <div className="form-group w-100 ml-2 mb-4 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="roleId">Role</label>
            <select
              {...register("roleId")}
              id="roleId"
              className="form-control"
            >
              {roles
                .filter((r) => r.name !== "Super.Admin")
                .map((r) => {
                  return (
                    <option
                      key={r.id}
                      value={r.id}
                      selected={r.id === user.role?.id}
                    >
                      {toUpperCaseFirstLetter(r.name)}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary mt-3 d-flex align-items-center justify-content-center align-self-end"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          <span>Update</span>
        </button>
      </form>
    </>
  );
};
