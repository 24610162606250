import moment from "moment";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { FaEllipsisH, FaEllipsisV } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import SureToDelete from "../../components/common/SureToDelete";
import {
  AddActivities,
  AddWorkPlanActivities,
  RescheduleActivity,
  UpdateActivity,
  UpdateActivityStatus,
} from "../../components/forms";
// import Paginate from "../../components/common/paginate";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import {
  Activity,
  Objective,
  ROLES,
  TableHeader,
  TenantCode,
} from "../../models";
import {
  ENDPOINTS,
  getCurrentTenant,
  getCurrentUser,
  httpService,
} from "../../services";

type Props = {
  headers: TableHeader[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  objectives: Objective[];
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const ObjectivesTable = ({
  headers,
  objectives,
  reload,
  open,
  setReload,
}: Props) => {
  const toggle = withReactContent(Swal);
  const currentUser = getCurrentUser();
  const tenant = getCurrentTenant();

  const onToggle = (activity: Activity) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: (
        <UpdateActivityStatus
          activity={activity}
          reload={reload}
          setReload={setReload}
        />
      ),
    });
  };

  // const onSendEmail = (activity: Activity) => {
  //   toggle.fire({
  //     showConfirmButton: false,
  //     showCloseButton: true,
  //     allowOutsideClick: false,
  //     //   html: <NewEmail action={activity} />,
  //   });
  // };

  const onAddActivities = (objective: Objective) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "70%",
      html: (
        <AddActivities
          objective={objective}
          reload={reload}
          setReload={setReload}
        />
      ),
    });
  };

  const onAddWorkPlanActivities = (objective: Objective) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "70%",
      html: (
        <AddWorkPlanActivities
          objective={objective}
          reload={reload}
          setReload={setReload}
        />
      ),
    });
  };

  const onReschedule = (actionId: number) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: (
        <RescheduleActivity
          activityId={actionId}
          reload={reload}
          setReload={setReload}
        />
      ),
    });
  };

  const onUpdateActivity = (activity: Activity) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: (
        <UpdateActivity
          activity={activity}
          reload={reload}
          setReload={setReload}
        />
      ),
    });
  };

  const handleDelete = async (id: number, ENDPOINT?: ENDPOINTS) => {
    if (ENDPOINT) {
      await httpService(ENDPOINT).delete(id);
      setReload(!reload);
    }
  };

  const onDelete = (id: number, ENDPOINT: ENDPOINTS) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: (
        <SureToDelete id={id} ENDPOINT={ENDPOINT} onDelete={handleDelete} />
      ),
    });
  };

  // const isDisabled = (departmentId: number) => {
  //   if (
  //     currentUser?.roleName === ROLES.ADMIN ||
  //     currentUser?.roleName === ROLES.EDITOR ||
  //     currentUser?.roleName === ROLES.SUPER_ADMIN ||
  //     (currentUser?.roleName === ROLES.DIRECTOR &&
  //       tenant &&
  //       +tenant?.id === 3 &&
  //       +currentUser.departmentId === departmentId)
  //   ) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  // const disableEdit = (activity: Activity) => {
  //   if (currentUser && currentUser.roleName === ROLES.DIRECTOR) {
  //     return true;
  //   } else {
  //     if (
  //       currentUser &&
  //       currentUser.roleName === ROLES.DIRECTOR &&
  //       +currentUser.departmentId !== activity.departmentId
  //     ) {
  //       return true;
  //     }
  //     return false;
  //   }
  // };

  return (
    <div>
      {/* <RenderHelmet /> */}
      {objectives.map((obj) => {
        return (
          <div id="toggleAccordion" key={obj.id}>
            <div className="card mt-2">
              <div className="px-2 py-3">
                <div role="menu" className="d-flex justify-content-between">
                  <div
                    className="collapsed align-self-center d-flex"
                    data-toggle="collapse"
                    data-target={`#accordion${obj.id}`}
                    aria-controls={`accordion${obj.id}`}
                  >
                    <span className="objective_text">
                      {toUpperCaseFirstLetter(obj.topic)}
                    </span>
                    {/* <span>
                      <AccordionChevronIcon open={open} />
                    </span> */}
                  </div>
                  <div className="d-flex justify-content-end">
                    {/* <button
                      className="btn btn-dark btn-sm m-1 p-1"
                      onClick={() =>
                        tenant && +tenant?.id !== 3
                          ? onAddActivities(obj)
                          : onAddWorkPlanActivities(obj)
                      }
                      disabled={
                        obj &&
                        obj.activities &&
                        obj.activities.length > 0 &&
                        isDisabled(obj.activities[0].departmentId)
                      }
                    >
                      <span className="ml-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-plus"
                        >
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={5} y1={12} x2={19} y2={12} />
                        </svg>{" "}
                        Add Activity
                      </span>
                    </button>
                    <button
                      className="btn btn-sm btn-danger m-1 p-1"
                      onClick={() => onDelete(obj.id, ENDPOINTS.Objectives)}
                      disabled={
                        obj &&
                        obj.activities &&
                        obj.activities.length > 0 &&
                        isDisabled(obj.activities[0].departmentId)
                      }
                    >
                      <span className="mx-2">
                        <span className="mr-2">
                          <FaTrash style={{ width: "14px" }} />
                        </span>
                        Delete
                      </span>
                    </button> */}

                    <div className="dropdown custom-dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <FaEllipsisV size={"20px"} color="#555" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink1"
                      >
                        <button
                          onClick={() =>
                            tenant && +tenant?.id !== 3
                              ? onAddActivities(obj)
                              : onAddWorkPlanActivities(obj)
                          }
                          className="dropdown-item"
                          disabled={
                            currentUser &&
                            obj &&
                            obj.activities &&
                            obj.activities.length > 0 &&
                            currentUser.roleName === ROLES.DIRECTOR
                              ? true
                              : false
                          }
                        >
                          Add Activity
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => onDelete(obj.id, ENDPOINTS.Objectives)}
                          disabled={
                            currentUser &&
                            obj &&
                            obj.activities &&
                            obj.activities.length > 0 &&
                            currentUser.roleName === ROLES.DIRECTOR
                              ? true
                              : false
                          }
                        >
                          Delete Objective
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id={`accordion${obj.id}`}
                className="collapse bg-gray"
                data-parent="#toggleAccordion"
              >
                <div className="card-body p-0 m-10 bg-gray">
                  {obj && obj.activities && obj.activities.length > 0 && (
                    <table className="table">
                      <thead>
                        <tr>
                          {headers.map((h) => (
                            <th style={{ fontSize: "10px" }} key={h.id}>
                              {h.name === "Department" &&
                              tenant &&
                              tenant.code === TenantCode.WP
                                ? "Section"
                                : h.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {obj.activities?.map((activity) => {
                          const remarks =
                            activity.remark !== null
                              ? activity.remark
                                  .split(",")
                                  .filter((r) => r.length)
                              : [];

                          return (
                            <tr key={activity.id} className="">
                              <td className="">
                                {toUpperCaseFirstLetter(activity.name)}
                              </td>
                              <td className="">
                                {toUpperCaseFirstLetter(
                                  activity?.section?.name ?? ""
                                )}
                              </td>

                              <td className="">
                                <ul>
                                  {remarks.length > 0 &&
                                    remarks.map((r) => <li>{r}</li>)}
                                </ul>
                              </td>
                              <td className="">
                                {activity.startDate &&
                                  moment(activity.startDate).format("L")}
                              </td>
                              <td className="">
                                {activity.endDate &&
                                  moment(activity.endDate).format("L")}
                              </td>
                              <td className="">
                                {activity.updatedAt !== null
                                  ? moment(activity.updatedAt).format("L")
                                  : ""}
                              </td>
                              <td className="">
                                {activity.duration > 0 &&
                                activity.updatedAt !== null ? (
                                  <span className="text-success">
                                    {activity.duration > 1
                                      ? `${activity.duration} Days`
                                      : `${activity.duration} Day`}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    {activity.duration > 1
                                      ? `${activity.duration} Days`
                                      : `${activity.duration} Day`}
                                  </span>
                                )}
                              </td>
                              <td className="">
                                <div
                                  className="progress br-30"
                                  style={{ height: "1.5vh" }}
                                >
                                  <div
                                    className={`progress-bar ${getProgress(
                                      activity.progress
                                    )}`}
                                    role="progressbar"
                                    style={{ width: `${activity.progress}` }}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  >
                                    {activity.progress}
                                  </div>
                                </div>
                              </td>
                              <td className=" text-center">
                                <ul>
                                  {activity.reschedules !== null &&
                                    activity.reschedules.length > 0 &&
                                    activity.reschedules.map((r) => (
                                      <li key={r.id}>
                                        {moment(r.endDate).format("L")}
                                      </li>
                                    ))}
                                </ul>
                              </td>
                              {/* <td className=""> */}
                              {/* <div className="d-flex justify-content-left">
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => onReschedule(activity.id)}
                                    disabled={
                                      currentUser &&
                                      (currentUser.roleName === "Editor" ||
                                        +currentUser?.departmentId ===
                                          activity.departmentId)
                                        ? false
                                        : true
                                    }
                                  >
                                    Reschedule
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => onToggle(activity)}
                                    disabled={
                                      currentUser !== null &&
                                      (currentUser.roleName === "Editor" ||
                                        +currentUser.departmentId ===
                                          activity.departmentId)
                                        ? false
                                        : true
                                    }
                                  >
                                    Update
                                  </button>
                                  <button className="btn btn-sm btn-danger">
                                    Delete
                                  </button>
                                </div> */}
                              {/* <FaEllipsisH size={"20px"} color="#555" />
                              </td> */}
                              <td className="text-center">
                                <div className="dropdown custom-dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    href="#"
                                    role="button"
                                    id="dropdownMenuLink1"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <FaEllipsisH size={"20px"} color="#555" />
                                  </a>

                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink1"
                                  >
                                    <button
                                      className="dropdown-item"
                                      onClick={() => onReschedule(activity.id)}
                                      disabled={
                                        currentUser &&
                                        currentUser.roleName === ROLES.DIRECTOR
                                          ? true
                                          : false
                                      }
                                    >
                                      Reschedule
                                    </button>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => onToggle(activity)}
                                      disabled={
                                        currentUser !== null &&
                                        (currentUser.roleName !==
                                          ROLES.DIRECTOR ||
                                          +currentUser.sectionId ===
                                            activity.sectionId)
                                          ? false
                                          : true
                                      }
                                    >
                                      Submit
                                    </button>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => onUpdateActivity(activity)}
                                      disabled={
                                        currentUser &&
                                        currentUser.roleName === ROLES.DIRECTOR
                                          ? true
                                          : false
                                      }
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className="dropdown-item"
                                      onClick={() =>
                                        onDelete(
                                          activity.id,
                                          ENDPOINTS.Activities
                                        )
                                      }
                                      disabled={
                                        currentUser &&
                                        currentUser.roleName === ROLES.DIRECTOR
                                          ? true
                                          : false
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

// function RenderHelmet() {
//   return (
//     <Helmet>
//       <link
//         href="/assets/css/scrollspyNav.css"
//         rel="stylesheet"
//         type="text/css"
//       />
//       <link
//         href="/assets/css/components/tabs-accordian/custom-accordions.css"
//         rel="stylesheet"
//         type="text/css"
//       />
//       <link
//         rel="stylesheet"
//         type="text/css"
//         href="/plugins/table/datatable/datatables.css"
//       />
//       <link
//         rel="stylesheet"
//         type="text/css"
//         href="/plugins/table/datatable/dt-global_style.css"
//       />
//       <script src="/plugins/table/datatable/datatables.js"></script>
//       <script src="/assets/js/scrollspyNav.js"></script>
//       <script src="/assets/js/components/ui-accordions.js"></script>
//     </Helmet>
//   );
// }
export default ObjectivesTable;

export function getProgress(progress: string) {
  if (progress === "0%") {
    return "bg-danger";
  } else if (progress === "25%") {
    return "bg-danger";
  } else if (progress === "50%") {
    return "bg-warning";
  } else if (progress === "75%") {
    return "bg-warning";
  } else if (progress === "100%") {
    return "bg-success";
  }
}
