import { Controller, useFieldArray, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Activity, Department, Objective } from "../../models";
import { ENDPOINTS, httpService } from "../../services";
import { BsXSquareFill, BsPlusSquareFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import moment from "moment";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";

type Props = {
  objective: Objective;
  reload: boolean;
  setReload: (reload: boolean) => void;
};

export const AddActivities = ({ objective, reload, setReload }: Props) => {
  const [departments, setDepartments] = useState<Department[]>([]);

  const validationSchema = Yup.object()
    .shape({
      activities: Yup.array(
        Yup.object().shape({
          name: Yup.string().required("Name is required"),
          sectionId: Yup.number().required("Section id is required"),
          startDate: Yup.string().nullable().default(undefined),
          endDate: Yup.string().nullable().default(null),
        })
      ),
    })
    .required();

  const formOptions = {
    defaultValues: { activities: [{ endDate: null }] },
    resolver: yupResolver(validationSchema),
  };

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "activities", // unique name for the Field Array
  });

  useEffect(() => {
    (async function fetchDepartments() {
      const res = await httpService(ENDPOINTS.AllDepartments).getAll();

      setDepartments(res.data ?? []);
    })();
  }, []);

  const onSubmit = async (values: any) => {
    await httpService(ENDPOINTS.AddActivities).update(objective.id, {
      activities: values.activities.map((a: Activity) => {
        return {
          ...a,
          startDate:
            a.startDate === undefined ? null : moment(a.startDate).toDate(),
          endDate:
            a.endDate === "" || a.endDate === null
              ? null
              : moment(a.endDate).toDate(),
          sectionId: a.sectionId,
          departmentId: departments.filter((d) =>
            d.sections.map((s) => s.id).includes(a.sectionId)
          )[0].id,
        };
      }),
    });
    setReload(!reload);
    reset();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column w-100 mt-4"
      >
        {fields.map((item, index) => {
          return (
            <div
              className="py-1 d-flex mt-4 align-items-center align-content-space-between"
              key={item.id}
            >
              <input
                type="text"
                className="form-control mr-2"
                id="name"
                placeholder="Activity name"
                {...register(`activities[${index}].name`)}
              />
              {/* <select
                {...register(`activities[${index}].departmentId`)}
                id="departmentId"
                className="form-control"
              >
                {departments.map((d) => {
                  return (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  );
                })}
              </select> */}
              <select
                className="form-control form-control-sm nested"
                {...register(`activities[${index}].sectionId`)}
                id="sectionId"
              >
                <option value="">Select Section</option>
                {departments.map((d) => {
                  return (
                    <optgroup label={d.name} key={d.id}>
                      {d.sections.map((s) => {
                        return (
                          <option value={s.id} key={s.id}>
                            {toUpperCaseFirstLetter(s.name)}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
              </select>

              <div className="mx-2 w-100">
                <Controller
                  control={control}
                  name={`activities[${index}].startDate`}
                  render={({ field }) => (
                    <DatePicker
                      className="form-control"
                      placeholderText="Select start date"
                      onChange={field.onChange}
                      selected={field.value}
                    />
                  )}
                />
              </div>
              <div className="mx-2 w-100">
                <Controller
                  control={control}
                  name={`activities[${index}].endDate`}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        className="form-control"
                        placeholderText="Select end date"
                        onChange={field.onChange}
                        selected={field.value}
                      />
                    );
                  }}
                />
              </div>
              <div>
                <BsXSquareFill
                  style={{ cursor: "pointer", color: "#e7515a" }}
                  fontSize="30px"
                  onClick={() => remove(index)}
                />
              </div>
            </div>
          );
        })}
        <div style={{ alignSelf: "flex-start" }} className="mt-2">
          <span className="mr-2">Add Activities</span>
          <BsPlusSquareFill
            style={{ cursor: "pointer", color: "#1b55e2" }}
            fontSize="30px"
            onClick={() => {
              append({ name: "", sectionId: 0, endDate: "" });
            }}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary mt-3 d-flex align-items-center justify-content-center align-self-end"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          <span>Save</span>
        </button>
      </form>
    </>
  );
};
