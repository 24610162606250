import { ENDPOINTS } from "../../services";

type Props = {
  ENDPOINT?: ENDPOINTS;
  id: number;
  onDelete: (id: number, ENDPOINT?: ENDPOINTS) => void;
};
const SureToDelete = ({ id, ENDPOINT, onDelete }: Props) => {
  const handleDelete = () => {
    onDelete(id, ENDPOINT);
  };
  return (
    <div className="d-flex flex-column mt-4">
      <div className="form-group d-flex flex-column align-items-start align-content-center">
        <h3>Are you sure to delete?</h3>
      </div>
      <button
        className="btn btn-danger mt-3 align-self-end"
        onClick={handleDelete}
      >
        Delete
      </button>
    </div>
  );
};

export default SureToDelete;
